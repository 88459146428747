import { Grid, Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import Claim from './Claim';
import Header from './Header';
import NavBar from './NavBar';
import NoDataFoundText from './NoDataFoundText';
import SearchField from './SearchField';
import SuggestedClaimButtons from './SuggestedClaimButtons';
import Summary from './Summary';
import { LIGHT_GREY } from 'utils/theme';

const PlaygroundV2Page = () => {
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const claimHasEvidence = claim.evidence && claim.evidence?.length > 0;
  const isNoDataFound = useAppSelector((state) => state.claimsV2.isNoDataFound);

  return (
    <Grid
      container
      sx={{ height: '100vh', overflow: 'auto', backgroundColor: LIGHT_GREY }}
      p={4}
    >
      <NavBar />

      <Grid container item justifyContent="center">
        <Stack width="80%" maxWidth="1200px">
          <Header />
          <SearchField />
          {!claimLoading && isNoDataFound && <NoDataFoundText />}
          {/* show when there is no data and not loading */}
          {!claimLoading && !claimHasEvidence && <SuggestedClaimButtons />}
          {/* show when there is data and loading */}
          {(claimLoading || claimHasEvidence) && <Summary />}
          {/* show when there is data and not loading */}
          {!claimLoading && claimHasEvidence && <Claim />}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PlaygroundV2Page;
