import React from 'react';
import { Typography, IconButton, useMediaQuery, Stack } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from 'components/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BIG_FONT, ISMOBILE_BREAKPOINT } from 'utils/theme';

interface UserProps {
  openUserProfile: boolean;
  handleClickUserProfile: (event: React.MouseEvent<HTMLElement>) => void;
}

const User = (props: UserProps) => {
  const { handleClickUserProfile, openUserProfile } = props;
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const { user } = useAuth0();

  return (
    <Stack direction="row" alignItems="center">
      {isMobile ? (
        <IconButton onClick={handleClickUserProfile}>
          <Avatar />
        </IconButton>
      ) : (
        <>
          <Avatar />
          <Typography fontSize={BIG_FONT} p={0.5}>
            {user?.given_name ?? ''}
          </Typography>
          <IconButton onClick={handleClickUserProfile}>
            {openUserProfile ? (
              <KeyboardArrowUpIcon sx={{ color: 'black' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: 'black' }} />
            )}
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default User;
