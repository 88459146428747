import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { LIGHT_BLACK } from 'utils/theme';
import './index.css';

const ExpandedText = ({ text }: { text: string }) => (
  <Typography color={LIGHT_BLACK} fontSize="14px">
    {text}
  </Typography>
);

const SeeMoreButton = ({ name, text }: { name: string; text: string }) => {
  const [expanded, setExpanded] = useState<string>('');

  const toggleExpand = () => {
    setExpanded(expanded === name ? '' : name);
  };

  return (
    <Box>
      <Typography
        color="#545454"
        fontSize="16px"
        className={`text ${expanded ? 'expanded' : ''}`}
      >
        {text}
      </Typography>
      {text && text.length > 300 && (
        <Button
          onClick={toggleExpand}
          sx={{
            mt: 1,
            textTransform: 'none',
            p: 0,
            '&:hover': { background: 'none' },
          }}
          endIcon={
            expanded ? (
              <ExpandLessIcon sx={{ color: LIGHT_BLACK }} />
            ) : (
              <ExpandMoreIcon sx={{ color: LIGHT_BLACK }} />
            )
          }
        >
          {expanded === name ? (
            <ExpandedText text="See less" />
          ) : (
            <ExpandedText text="See more" />
          )}
        </Button>
      )}
    </Box>
  );
};

export default SeeMoreButton;
