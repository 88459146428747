const FREE_VERSION_WORD_COUNT = 30;
// const MIN_WORD_COUNT = 3;

const wordsCount = (str) => {
  return str.trim().split(/\s+/).length;
};

export const isTooLongText = (searchValue: string) =>
  wordsCount(searchValue) > FREE_VERSION_WORD_COUNT;

export const getErrorText = (searchValue: string) => {
  if (wordsCount(searchValue) > FREE_VERSION_WORD_COUNT) {
    return 'Text is too long, upgrade to analyze';
  }
};
