import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const Avatar = () => {
  const { user } = useAuth0();

  return (
    <>
      {user && (
        <MuiAvatar
          style={{ marginRight: 4 }}
          sx={{
            width: 38,
            height: 38,
          }}
        >
          {user.picture ? (
            <img
              alt="userPhoto"
              style={{ width: 'inherit' }}
              src={user.picture}
            />
          ) : (
            <PermIdentityIcon fontSize={'medium'} />
          )}
        </MuiAvatar>
      )}
    </>
  );
};

export default Avatar;
