import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'core/store/hooks';
import { setLoginModalOpen } from 'core/store/loginSlice';

const Login = () => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const loginModalOpen = useAppSelector((state) => state.login.loginModalOpen);

  const handleClose = () => {
    dispatch(setLoginModalOpen());
  };

  return (
    <Dialog open={loginModalOpen} onClose={handleClose}>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: 'white',
          }}
          p="1em"
        >
          <Typography variant="h4">
            Fact-check your own text by logging in or signing up for free
          </Typography>
          <Typography variant="body1">
            Try Factiverse AI Editor for free. After a 2 week trial you can
            upgrade to Pro for €10 per month.
          </Typography>
          <Stack direction="row" spacing={2}>
            <YellowButton onClick={() => loginWithRedirect()}>
              Sign Up
            </YellowButton>
            <YellowBorderButton onClick={() => loginWithRedirect()}>
              Log In
            </YellowBorderButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
