import CheckIcon from '@mui/icons-material/Check';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';

const SummaryText = () => {
  const summary = useAppSelector((state) => state.claimsV2.claim.summary);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Box sx={{ m: 2 }}>
      {claimLoading ? (
        <Box>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </Box>
      ) : (
        <Box>
          {summary ? (
            summary.map((sum, key) => (
              <Grid container mb={2} key={key}>
                <Grid item xs={1} textAlign="center">
                  <CheckIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography>{sum}</Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography ml={1}> No summary data found</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SummaryText;
