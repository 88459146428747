import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  allowTracking: boolean;
  seenTutorial: boolean;
  onboardingQuestions: OnboardingQuestion[];
}

const initialState: AppState = {
  allowTracking: false,
  seenTutorial: true,
  onboardingQuestions: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllowTracking: (state: AppState, action: PayloadAction<boolean>) => {
      return { ...state, allowTracking: action.payload };
    },
    setSeenTutorial: (state: AppState, action: PayloadAction<boolean>) => {
      return { ...state, seenTutorial: action.payload };
    },
    setOnboardingQuestions: (
      state: AppState,
      action: PayloadAction<OnboardingQuestion[]>
    ) => {
      return { ...state, onboardingQuestions: action.payload };
    },
  },
});

export const { setAllowTracking, setSeenTutorial, setOnboardingQuestions } =
  userSlice.actions;
export default userSlice.reducer;
