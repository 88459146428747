import { Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { BORDER_RADIUS, ISMOBILE_BREAKPOINT, YELLOW } from 'utils/theme';

interface PageCardProps {
  page: TutorialPage;
}

const TutorialCard: React.FC<PageCardProps> = ({ page }) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  return (
    <Stack alignItems="center" borderRadius={BORDER_RADIUS} spacing={2}>
      <>
        <Typography variant={isMobile ? 'h6' : 'h4'}>{page.title}</Typography>
        <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
          {page.subtitle}
        </Typography>
        {page.image !== undefined && (
          <img
            style={{
              border: `2px solid ${YELLOW}`,
              borderRadius: 10,
              maxWidth: isMobile ? '80vw' : '30vw',
              maxHeight: isMobile ? '50vh' : '30vh',
            }}
            src={page.image}
            alt="title"
          />
        )}
      </>
    </Stack>
  );
};

export default TutorialCard;
