import React from 'react';
import YellowBorderButton from './YellowBorderButton';
import { useAuth0 } from '@auth0/auth0-react';

const ManageSubscriptionButton: React.FC = () => {
  const { user } = useAuth0();

  return (
    <YellowBorderButton
      onClick={() => {
        window.open(
          'https://payment.factiverse.no/p/login/8wM6skdVk4bza9G144?prefilled_email=' +
            user?.email,
          '_blank'
        );
      }}
    >
      Manage subscription
    </YellowBorderButton>
  );
};

export default ManageSubscriptionButton;
