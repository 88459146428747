import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Accordion as MUIAccordion,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import Source from 'components/Source';
import Sum from 'components/Sum';
import React, { useCallback } from 'react';
import { DISPUTED, MEDIUM_FONT, MIXED, SUPPORTED } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';
import { useUserMetaData } from 'hooks/useUserMetaData';

const Accordion = styled(MUIAccordion)<AccordionProps>(() => ({
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  '&.MuiAccordion-root': {
    margin: 0,
  },
  '&.MuiAccordion-root:before': {
    height: 0,
  },
  '& .MuiAccordionSummary-root': {
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
    margin: 0,
  },
}));

interface SourcesProps {
  claim: Claim;
  sources: Source[] | undefined;
  sourceFilter: SourceFilter;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
}

const Sources: React.FC<SourcesProps> = ({
  claim,
  sources,
  sourceFilter: filter,
  onFeedbackSourceSupport,
  onFeedbackSourceRelevance,
}) => {
  const { selectedSourceDate, selectedDatabases } = useAppSelector(
    (state) => state.sourceFilter
  );
  const { liteSubscription } = useUserMetaData();

  const supportingSources = sources?.filter((item) => {
    return item.softmaxScore[1] >= 0.6;
  });

  const mixedSources = sources?.filter((item) => {
    return item.softmaxScore[1] > 0.4 && item.softmaxScore[1] < 0.6;
  });

  const disputingSources = sources?.filter((item) => {
    return item.softmaxScore[1] <= 0.4;
  });

  // when sorting dates
  // if we are sorting from new to old
  // null will be from 1970 January 1st
  // if we are sorting from old to new
  // null will be date of today

  const sortSourcesByDate = useCallback(
    (sources: Source[]) => {
      const sourcesCopy = [...sources];
      const nullOldDate = new Date(70, 0, 1);
      const nullNewDate = new Date();
      if (selectedSourceDate === '') return sources;
      else if (selectedSourceDate === 'mostRecent') {
        return sourcesCopy?.sort(
          (a, b) =>
            new Date(b.publishDate ?? nullOldDate).getTime() -
            new Date(a.publishDate ?? nullOldDate).getTime()
        );
      } else if (selectedSourceDate === 'leastRecent') {
        return sourcesCopy?.sort(
          (a, b) =>
            new Date(a.publishDate ?? nullNewDate).getTime() -
            new Date(b.publishDate ?? nullNewDate).getTime()
        );
      }
    },
    [selectedSourceDate]
  );

  const sortSourcesByDatabase = useCallback(
    (sources: Source[]) => {
      const sourcesCopy = [...sources];
      if (selectedDatabases.length === 0) return [];
      if (selectedDatabases.length > 0) {
        return sourcesCopy.filter((s) =>
          selectedDatabases.includes(s.searchEngine ?? '')
        );
      }
    },
    [selectedDatabases]
  );

  /**
   * Returns a block of sources for a given source type
   *
   * @param {Sources[]} sources List of filtered sources
   * @param {string} color Color of the sum
   * @param {string} sourceName Displayed name of the source type
   * @return {JSX.Element} Accordion component with sources
   */
  const getSourceBlock = (
    sources: Source[],
    color: string,
    sourceName: string
  ) => {
    const filteredSources = sortSourcesByDate(
      sortSourcesByDatabase(sources) as Source[]
    );
    if (liteSubscription) {
      // Show only up to 3 sources if not on Pro subscription
      sources = sources.slice(0, 3);
    }
    return (
      <>
        {filteredSources?.length !== 0 && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack flexDirection="row" alignItems="center">
                <Sum
                  color={color}
                  count={filteredSources?.length as number}
                  isSum={true}
                />
                <Typography ml={1} variant="subtitle1">
                  {sourceName}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {filteredSources?.map((item) => {
                  return (
                    <Source
                      claim={claim}
                      source={item}
                      key={Math.random()}
                      onFeedbackSourceSupport={onFeedbackSourceSupport}
                      onFeedbackSourceRelevance={onFeedbackSourceRelevance}
                    />
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  };

  return (
    <Stack>
      {filter.displaySupporting &&
        supportingSources !== undefined &&
        supportingSources.length > 0 &&
        getSourceBlock(supportingSources, SUPPORTED, 'Supporting')}
      {filter.displayMixed &&
        mixedSources !== undefined &&
        mixedSources.length > 0 &&
        getSourceBlock(mixedSources, MIXED, 'Mixed')}
      {filter.displayDisputing &&
        disputingSources !== undefined &&
        disputingSources.length > 0 &&
        getSourceBlock(disputingSources, DISPUTED, 'Disputing')}
      {sources?.length === 0 && (
        <Typography fontSize={MEDIUM_FONT} ml={4} mt={1}>
          No sources found for this claim
        </Typography>
      )}
    </Stack>
  );
};

export default Sources;
