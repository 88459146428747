import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, CircularProgress } from '@mui/material';
import { LIGHT_GREY, YELLOW } from 'utils/theme';
import stripeConfig from '../../stripeConfig.json';

type BuyNowButtonProps = {
  tier: 'Lite' | 'Pro';
  interval: 'monthly' | 'yearly';
};

const BuyNowButton: React.FC<BuyNowButtonProps> = ({ tier, interval }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [disabled, setDisabled] = useState(false);

  const buy = useCallback(async () => {
    const authToken = await getAccessTokenSilently();
    let url = stripeConfig.stripe_webhook.development;
    setDisabled(true);
    if (process.env.DEPLOYMENT === 'testing') {
      url = stripeConfig.stripe_webhook.testing;
    }
    if (process.env.DEPLOYMENT === 'production') {
      url = stripeConfig.stripe_webhook.production;
    }

    try {
      const stripeResponse = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        // subscriptionPlan and subscriptionInterval have to be sent in for the webhook to create the correct Stripe checkout
        body: JSON.stringify({
          subscriptionPlan: tier,
          subscriptionInterval: interval,
        }),
      }).then(async (response) => response.json());
      window.location.href = stripeResponse.url;
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  }, [getAccessTokenSilently, interval, tier]);

  return (
    <Button
      sx={{
        borderRadius: '20px',
        color: 'black',
        backgroundColor: 'white',
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: LIGHT_GREY,
        },
      }}
      onClick={() => buy()}
      disabled={disabled}
    >
      {disabled ? (
        <CircularProgress size="3vh" sx={{ color: YELLOW }} />
      ) : (
        'Upgrade Subscription'
      )}
    </Button>
  );
};

export default BuyNowButton;
