import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { DARK_GREY, ISMOBILE_BREAKPOINT, MIXED, YELLOW } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';
import DataSources from './DataSources';
import DateFilter from './DateFilter';
import SourceType from './SourceType';

export const sourceTypeValues = ['Supporting', 'Mixed', 'Disputing'];
export const databaseFilterValues = [
  'Google',
  'Bing Search',
  'Wikipedia',
  'Semantic Scholar',
  'GoogleClaim',
  'ElasticSearch',
  'You.com',
];

const FilterPopover = ({
  onChangeType,
  onChangeDate,
  onChangeDatabase,
}: {
  onChangeType: (checkedItems: string[]) => void;
  onChangeDate: (checkedItem: string) => void;
  onChangeDatabase: (checkedItems: string[]) => void;
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const { selectedSourceDate, selectedDatabases } = useAppSelector(
    (state) => state.sourceFilter
  );
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const open = Boolean(popoverAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <>
      <Box>
        <IconButton
          aria-label="filter"
          onClick={handleClick}
          sx={{
            background: YELLOW,
            borderRadius: '8px',
            '&:hover': { background: MIXED },
          }}
        >
          <FilterAltOutlinedIcon
            id="filter"
            fontSize={isMobile ? 'small' : 'medium'}
            sx={{ color: 'white' }}
          />
        </IconButton>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        sx={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
        PaperProps={{
          style: {
            border: `1.5px solid ${YELLOW}`,
            boxShadow: '0px -1px 30px 0px rgba(248,196,8,0.51)',
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          p={2}
        >
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography color={YELLOW} variant={isMobile ? 'subtitle1' : 'h6'}>
              Customise your results
            </Typography>
            <Button
              onClick={() => {
                onChangeType(sourceTypeValues);
                onChangeDatabase(databaseFilterValues);
              }}
              variant="text"
              sx={{
                color: DARK_GREY,
                textDecoration: 'underline',
                fontSize: '0.75',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              Clear filters
            </Button>
          </Stack>

          <Box>
            <Typography pb={1} variant={isMobile ? 'subtitle2' : 'subtitle1'}>
              Select your preferred data
            </Typography>
            <DataSources
              selectedDatabases={selectedDatabases}
              onChangeDatabase={onChangeDatabase}
            />
          </Box>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <DateFilter
              selectedDate={selectedSourceDate}
              onClick={(value) => onChangeDate(value)}
            />
            <SourceType onChangeType={onChangeType} />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default FilterPopover;
