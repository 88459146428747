import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  claims: Claim[];
  searchValue: string;
  claim: Claim;
  claimLoading: boolean;
  linearProgressValue: number;
  sortedSources: Source[];
  selectedSortingAccordion: string;
  isNoDataFound: boolean;
}

const initialState: AppState = {
  claims: [],
  searchValue: '',
  claim: {} as Claim,
  claimLoading: false,
  linearProgressValue: 0,
  sortedSources: [],
  selectedSortingAccordion: 'All',
  isNoDataFound: false,
};

const claimsV2Slice = createSlice({
  name: 'claimsV2',
  initialState,
  reducers: {
    setSearchValue: (state: AppState, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setClaimResults: (state: AppState, action: PayloadAction<Claim>) => {
      state.claim = action.payload;
    },
    setClaimLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.claimLoading = action.payload;
    },
    incrementLinearProgress: (state) => {
      if (state.linearProgressValue >= 90) {
        return;
      }
      state.linearProgressValue += 10;
    },
    setLinearProgressValue: (
      state: AppState,
      action: PayloadAction<number>
    ) => {
      state.linearProgressValue = action.payload;
    },
    setSortedSources: (state: AppState, action: PayloadAction<Source[]>) => {
      state.sortedSources = action.payload;
    },
    setSelectedSortingaccordion: (
      state: AppState,
      action: PayloadAction<string>
    ) => {
      state.selectedSortingAccordion = action.payload;
    },
    setIsNoDataFound: (state: AppState, action: PayloadAction<boolean>) => {
      state.isNoDataFound = action.payload;
    },
  },
});

export const {
  setSearchValue,
  setClaimResults,
  setClaimLoading,
  incrementLinearProgress,
  setLinearProgressValue,
  setSortedSources,
  setSelectedSortingaccordion,
  setIsNoDataFound,
} = claimsV2Slice.actions;
export default claimsV2Slice.reducer;
