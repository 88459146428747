import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { YELLOW } from 'utils/theme';

/*
 * A generic UI element to give feedback.
 * Displays a short description (which changes once feedback was given) and thumbs up/down.
 */
interface ThumbsUpDownProps {
  thumbsUp: boolean | undefined;
  labelBefore: string;
  onChangeThumbsUpDown: OnChangeThumbsUpDown;
}

const ThumbsUpDown: React.FC<ThumbsUpDownProps> = ({
  thumbsUp,
  labelBefore,
  onChangeThumbsUpDown,
}) => {
  return (
    <Stack alignItems="center">
      <Typography variant={'subtitle1'}>{labelBefore}</Typography>
      <Stack direction="row">
        <Tooltip title={'Agree'} arrow>
          <IconButton
            color={thumbsUp ? 'primary' : undefined}
            onClick={() => onChangeThumbsUpDown(true)}
            sx={{
              color: thumbsUp ? YELLOW : undefined, // TODO: update MUI theme
            }}
          >
            <ThumbUpIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Disagree'} arrow>
          <IconButton
            color={thumbsUp != undefined && !thumbsUp ? 'primary' : undefined}
            onClick={() => onChangeThumbsUpDown(false)}
            sx={{
              color: thumbsUp !== undefined && !thumbsUp ? YELLOW : undefined,
            }}
          >
            <ThumbDownIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ThumbsUpDown;
