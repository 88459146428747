import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  LinearProgress,
  Popover,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import useUserMetaData from 'hooks/useUserMetaData';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRIGHT_YELLOW, YELLOW } from 'utils/theme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: BRIGHT_YELLOW,
  },
}));

interface ProfileProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  id: string;
}

const Profile: React.FC<ProfileProps> = ({
  open,
  anchorEl,
  handleClose,
  id,
}) => {
  const { user, isAuthenticated, logout } = useAuth0();

  const navigate = useNavigate();

  const { noSubscription, trialSubscription, proSubscription, valueByDay } =
    useUserMetaData();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  const dayCount = moment(user?.editor_created_at)
    .add('14', 'day')
    .diff(moment(), 'days');

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Stack spacing={2} p={2}>
        {noSubscription && (
          <Box>
            <Typography>Trial version has ended</Typography>
          </Box>
        )}
        {trialSubscription && (
          <>
            <Typography align="center">
              {dayCount === 0
                ? 'Last day of free trial'
                : dayCount > 0
                ? `${dayCount} day${
                    dayCount === 1 ? '' : 's'
                  } left of free trial`
                : 'Your free trial has expired.'}
            </Typography>
            {dayCount >= 0 && (
              <BorderLinearProgress
                value={valueByDay?.value}
                variant="determinate"
              />
            )}
          </>
        )}
        {noSubscription || trialSubscription ? (
          <YellowBorderButton
            onClick={() => {
              navigate('/pricing');
            }}
          >
            Upgrade Subscription
          </YellowBorderButton>
        ) : (
          <Box>
            <Typography
              fontSize="1.5rem"
              color={YELLOW}
              fontWeight="bold"
              pb={2}
            >
              {proSubscription ? 'Factiverse Pro' : 'Factiverse Lite'}
            </Typography>
            <YellowBorderButton
              onClick={() => {
                window.open(
                  'https://payment.factiverse.no/p/login/8wM6skdVk4bza9G144?prefilled_email=' +
                    user?.email,
                  '_blank'
                );
              }}
            >
              Manage subscription
            </YellowBorderButton>
          </Box>
        )}
        {user && (
          <Stack spacing={1} pt={2} maxWidth="300px">
            {isAuthenticated && (
              <YellowButton onClick={handleLogout}>Log out</YellowButton>
            )}
          </Stack>
        )}
      </Stack>
    </Popover>
  );
};

export default Profile;
