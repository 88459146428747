import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

interface UserOnboardingQuestionsResponse {
  _id: string;
  collection: string;
}

/**
 * Send user onboarding answers to the api
 *
 * @param {string} selectedWritingMatter User selected writing matter
 * @param {string} selectedTypeOfWriting User selected type of writing
 * @param {string} selectedPlace User selected place heard from
 * @param {string} selectedIndustry User selected place heard from
 * @return {object} The api response
 */
export function userOnboardingQuestionsRequest(
  selectedWritingMatter,
  selectedTypeOfWriting,
  selectedPlace,
  selectedIndustry
): Promise<AxiosResponse<UserOnboardingQuestionsResponse>> {
  const api = getAxiosInstance();
  return api.post('user', {
    onboarding_questions: [
      { answer: selectedWritingMatter },
      { answer: selectedTypeOfWriting },
      { answer: selectedPlace },
      { answer: selectedIndustry },
    ],
  });
}

interface GetUserResponse {
  allow_tracking: boolean;
  seen_tutorial: boolean;
  onboarding_questions: Array<OnboardingQuestion>;
}

/**
 * get user data
 *
 * @return {object} The api response
 */
export function getUserRequest(): Promise<AxiosResponse<GetUserResponse>> {
  const api = getAxiosInstance();
  return api.get('user');
}

interface PostUserResponse {
  detail: string;
}

/**
 * post user data
 *
 * @param {boolean} allowTracking User allow tracking
 * @param {boolean} seenTutorial User seen tutorial
 * @param {array} onboardingQuestions User selected place heard from
 * @return {object} The api response
 */
export function postUserRequest(
  allowTracking: boolean,
  seenTutorial: boolean,
  onboardingQuestions: Array<OnboardingQuestion>
): Promise<AxiosResponse<PostUserResponse>> {
  const api = getAxiosInstance();
  return api.post('user', {
    allow_tracking: allowTracking,
    seen_tutorial: seenTutorial,
    onboarding_questions: onboardingQuestions,
  });
}

interface PutUserResponse {
  _id: string;
  collection: string;
}

/**
 * put user data
 *
 * @param {boolean} allowTracking User allow tracking
 * @param {boolean} seenTutorial User seen tutorial
 * @param {object} onboardingQuestions User selected place heard from
 * @return {array} The api response
 */
export function putUserRequest(
  allowTracking?: boolean,
  seenTutorial?: boolean,
  onboardingQuestions?: Array<OnboardingQuestion>
): Promise<AxiosResponse<PutUserResponse>> {
  const api = getAxiosInstance();
  return api.put('user', {
    allow_tracking: allowTracking,
    seen_tutorial: seenTutorial,
    onboarding_questions: onboardingQuestions,
  });
}

export function deleteUserRequest() {
  const api = getAxiosInstance();
  return api.delete('user');
}
