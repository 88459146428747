import { Box, Button, Stack, Typography } from '@mui/material';
import { databaseFilterValues } from 'components/FilterPopover';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DARK_GREY } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';
import { setSelectedDatabases } from 'core/store/sourceFilterSlice';

const MissingSources = ({ claim }) => {
  const dispatch = useDispatch();
  const selectedDatabases = useAppSelector(
    (state) => state.sourceFilter.selectedDatabases
  );

  const missingSources = useMemo(() => {
    const unSelectedDatabases = databaseFilterValues.filter(
      (database) => !selectedDatabases.includes(database)
    );

    return claim.evidence?.filter((evidence) =>
      unSelectedDatabases.includes(evidence.searchEngine ?? '')
    );
  }, [claim.evidence, selectedDatabases]);

  return (
    <Box>
      {missingSources && missingSources?.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color={DARK_GREY}>
            {missingSources?.length} source{missingSources.length > 1 && 's'}{' '}
            {missingSources.length > 1 ? 'are' : 'is'} hidden
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              dispatch(setSelectedDatabases(databaseFilterValues));
            }}
            sx={{
              color: DARK_GREY,
              textDecoration: 'underline',
              fontSize: '0.75',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Clear filters
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default MissingSources;
