import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import Donut from './components/Donut';
import SummaryText from './components/SummaryText';

const Summary = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  const SummaryHeader = () => {
    return (
      <Stack direction="row" alignItems="center" width="100%">
        {claimLoading ? (
          <Skeleton width="inherit" height={50} sx={{ mx: 2 }} />
        ) : (
          <Stack direction="row" alignItems="center" ml={3}>
            <Typography>Summary</Typography>
            <IconButton aria-label="Example">
              <Tooltip title="We summarize the main points of the found sources with the help of generative AI.">
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <Grid
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        my: 2,
        p: 3,
        boxShadow: '4px 4px 18px 0px rgba(128,155,181,0.1)',
      }}
    >
      <Grid container>
        <Grid item xs={6} borderRight="1px solid lightgrey">
          <SummaryHeader />
          <SummaryText />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Donut />
        </Grid>
      </Grid>
      {/* <ActionBox /> */}
    </Grid>
  );
};

export default Summary;
