import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Badge,
  Button,
  Drawer,
  Grid,
  Stack,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { ISMOBILE_BREAKPOINT, YELLOW } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';
import Sidebar from './Sidebar';

const ResultsSide = () => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const [openSidebar, setOpenSidebar] = useState(false);
  const claims = useAppSelector((state) => state.claims.claims);

  return (
    <>
      {isMobile ? (
        <>
          <Grid item mt={'40vh'}>
            <Badge
              badgeContent={claims.length}
              sx={{
                '& .MuiBadge-badge': {
                  color: 'black',
                  backgroundColor: YELLOW,
                },
              }}
            >
              <Button
                id="claims"
                sx={{
                  maxWidth: '30px',
                  minWidth: '30px',
                  padding: '6px',
                  border: `1px solid black`,
                }}
                size="small"
                onClick={() => setOpenSidebar(true)}
              >
                <ArrowBackIos
                  sx={{ position: 'relative', left: '5px', color: 'black' }}
                />
              </Button>
            </Badge>
          </Grid>
          <React.Fragment key={'right'}>
            <Drawer
              anchor={'right'}
              open={openSidebar}
              onClose={() => setOpenSidebar(false)}
            >
              <Stack direction={'row'} minWidth="280px" maxWidth="100vw">
                <Grid mt={'50vh'} maxWidth="5vw">
                  <Button
                    onClick={() => setOpenSidebar(false)}
                    sx={{ maxWidth: '20px', minWidth: '20px' }}
                  >
                    <ArrowForwardIos sx={{ color: 'black' }} />
                  </Button>
                </Grid>
                <Sidebar />
              </Stack>
            </Drawer>
          </React.Fragment>
        </>
      ) : (
        <>
          <Grid item xs={5} ml={2}>
            <Sidebar />
          </Grid>
        </>
      )}
    </>
  );
};

export default ResultsSide;
