import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Claim from 'components/Claim';
import ClaimSkeleton from 'components/ClaimSkeleton';
import FilterByStance, { getClaimStatus } from 'components/FilterByStance';
import FilterPopover, { sourceTypeValues } from 'components/FilterPopover';
import Loading from 'components/Loading';
import ClaimHint from 'components/Tutorial/ClaimHint';
import useClaimChecks from 'hooks/useClaimChecks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';
import {
  setSelectedDatabases,
  setSelectedSourceDate,
  setSelectedSources,
} from 'core/store/sourceFilterSlice';
import RandomHint from './components/RandomHint';

interface ClaimsProps {
  claims: Claim[];
}

const Claims: React.FC<ClaimsProps> = ({ claims }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const selectedSources = useAppSelector(
    (state) => state.sourceFilter.selectedSources
  );

  const { isClaimsArrayEmpty, isFindingClaims, noClaimsFound, isCheckingText } =
    useClaimChecks(claims);

  // TODO: add filter for sources
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sourceFilter, setSourceFilter] = useState<SourceFilter>({
    displaySupporting: true,
    displayMixed: true,
    displayDisputing: true,
  });

  const [claimFilter, setClaimFilter] = useState<ClaimFilter>({
    displayAll: true,
    displaySupported: true,
    displayMixed: true,
    displayDisputed: true,
  });

  useEffect(() => {
    if (selectedSources.length > 0) {
      setSourceFilter({
        displayMixed: selectedSources.includes(sourceTypeValues[1]),
        displayDisputing: selectedSources.includes(sourceTypeValues[2]),
        displaySupporting: selectedSources.includes(sourceTypeValues[0]),
      });
    } else {
      setSourceFilter({
        displayMixed: false,
        displayDisputing: false,
        displaySupporting: false,
      });
    }
  }, [selectedSources]);

  const sortClaims = (claims: Claim[]) => {
    const claimsCopy = [...claims];
    return claimsCopy.sort((claimA: Claim, claimB: Claim) => {
      if (claimA.position !== undefined && claimB.position !== undefined) {
        return claimA?.position - claimB?.position;
      } else return -1;
    });
  };

  const allClaimsChecked = useMemo(
    () => claims.every((checkedClaim) => checkedClaim.isBeingChecked === false),
    [claims]
  );

  return (
    <Stack alignItems="center">
      {noClaimsFound && !isCheckingText && (
        <Box p={2} bgcolor="#fae592" mt={3}>
          <Typography align="center">
            We couldn&apos;t find any claims in your text, however you can
            select a claim manually and analyze it.
          </Typography>
        </Box>
      )}
      <Box width="100%" pt={1}>
        <FilterByStance setFilter={setClaimFilter} />
      </Box>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        py={1}
      >
        <Box pl={4}>
          {!allClaimsChecked || isFindingClaims ? (
            <Loading
              labels={[
                'Detecting language',
                'Finding factual sentences',
                'Searching on Google',
                'Searching on Bing',
                'Searching on Semantic Scholar',
                'Browsing fact-checks',
                'Ranking results',
              ]}
            />
          ) : (
            <RandomHint />
          )}
        </Box>
        <Box pr={2}>
          <FilterPopover
            onChangeType={(checkedItems) => {
              dispatch(setSelectedSources(checkedItems));
            }}
            onChangeDate={(checkedItem) => {
              dispatch(setSelectedSourceDate(checkedItem));
            }}
            onChangeDatabase={(checkedItems) => {
              dispatch(setSelectedDatabases(checkedItems));
            }}
          />
        </Box>
      </Stack>
      <Divider sx={{ width: '100%' }} />
      {isClaimsArrayEmpty && !isCheckingText && <ClaimHint />}
      {isFindingClaims && (
        <Stack width="85%">
          <Skeleton height="4em" />
          <ClaimSkeleton />
        </Stack>
      )}

      <Box
        height={
          isMobile
            ? 'auto'
            : isClaimsArrayEmpty && !isCheckingText
            ? 'auto'
            : '68vh'
        }
        pt={isMobile ? '0' : 2}
        overflow={'auto'}
        width="100%"
      >
        {sortClaims(claims).map((item) => {
          const type = getClaimStatus(item);
          const display =
            (claimFilter.displaySupported && type === 'supported') ||
            (claimFilter.displayMixed && type === 'mixed') ||
            (claimFilter.displayDisputed && type === 'disputed') ||
            claimFilter.displayAll;
          if (!display) return;
          return (
            <Box key={Math.random()}>
              <Claim claim={item} sourceFilter={sourceFilter} />
              <Divider />
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export default Claims;
