import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Stack } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import { useCallback } from 'react';
import MenuLogo from '../../../assets/img/menuLogo.png';
import ManageSubscription from './components/ManageSubscription';

const NavBar = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return (
    <Box
      height="100vh"
      bgcolor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
      boxShadow="4px 4px 17px -3px rgba(128,155,181,0.13)"
      position="fixed"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Box marginBottom="20px" marginTop="40px">
        <img src={MenuLogo} alt="navbarLogo" width="65px" />
      </Box>
      {isAuthenticated && (
        <Avatar
          src={user?.picture}
          sx={{ width: '60px', height: '60px', marginTop: '20px' }}
        />
      )}
      {isAuthenticated ? (
        <Stack spacing={2} marginTop="auto" marginBottom="40px">
          <ManageSubscription />
          <YellowButton onClick={handleLogout}>Log out</YellowButton>
        </Stack>
      ) : (
        <Stack spacing={2} marginTop="auto" marginBottom="40px">
          <YellowBorderButton onClick={() => loginWithRedirect()}>
            Sign Up
          </YellowBorderButton>
          <YellowButton onClick={() => loginWithRedirect()}>
            Log In
          </YellowButton>
        </Stack>
      )}
    </Box>
  );
};

export default NavBar;
