import { Stack } from '@mui/material';
import LinearProgressBar from './components/LinearProgressBar';
import {
  SearchFieldBody,
  SearchFieldFooter,
} from './components/SearchFieldComponents';

const SearchField = () => {
  return (
    <Stack mt={4}>
      <SearchFieldBody />
      <SearchFieldFooter />
      <LinearProgressBar />
    </Stack>
  );
};

export default SearchField;
