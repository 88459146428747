import { Box, LinearProgress } from '@mui/material';
import { incrementLinearProgress } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const LinearProgressBar = () => {
  const dispatch = useDispatch();
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const linearProgressValue = useAppSelector(
    (state) => state.claimsV2.linearProgressValue
  );

  useEffect(() => {
    if (claimLoading) {
      const timer = setInterval(() => {
        dispatch(incrementLinearProgress());
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [claimLoading, dispatch]);

  return (
    <Box>
      {claimLoading ? (
        <LinearProgress variant="determinate" value={linearProgressValue} />
      ) : (
        <Box height="4px" />
      )}
    </Box>
  );
};

export default LinearProgressBar;
