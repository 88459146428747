import React from 'react';
import { Button } from '@mui/material';
import { YELLOW } from 'utils/theme';

interface NoBorderButtonProps {
  children: string;
  onClick: () => void;
}

const NoBorderButton = (props: NoBorderButtonProps) => {
  const { children, onClick } = props;
  return (
    <Button
      sx={{
        borderRadius: '20px',
        color: 'black',
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: YELLOW,
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default NoBorderButton;
