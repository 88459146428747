import { Card, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import {
  BORDER_COLOR,
  BORDER_RADIUS,
  LARGE_FONT,
  SMALL_FONT,
} from 'utils/theme';
import { PlayCircleOutlineSharp } from '@mui/icons-material';

interface LinkCardProps {
  title: string;
  maxWidth?: number;
  boldText?: string;
  description?: string;
  linkSrc?: string;
  linkText?: string;
}

/**
 * Creates the link element for the card
 *
 * @param {string} text The text to display for the link
 * @param {string} src The link's destination
 *
 * @return {React.FC} The link element
 */
const makeLink = (text: string, src: string) => {
  return (
    <Grid item>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item paddingBottom="4px">
          <Link
            sx={{ textDecoration: 'none', color: 'black', padding: '3px' }}
            href={src}
            target="_blank"
            rel="noopener"
          >
            <Typography fontSize={SMALL_FONT}>{text}</Typography>
          </Link>
        </Grid>
        <Grid item>
          <PlayCircleOutlineSharp sx={{ fontSize: '1.1rem' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

/**
 * A card with a title, bold text, description, and link
 *
 * @param {LinkCardProps} props The props for the LinkCard
 * @param {string} props.title The title of the card
 * @param {number} props.width The width of the card
 * @param {number} props.height The height of the card
 * @param {string} props.boldText The bold text of the card
 * @param {string} props.description The description of the card
 * @param {string} props.linkSrc The link's destination
 * @param {string} props.linkText The text to display for the link
 *
 * @return {React.FC} The LinkCard component
 */
const LinkCard: React.FC<LinkCardProps> = ({
  title,
  maxWidth,
  boldText,
  description,
  linkSrc,
  linkText,
}) => {
  return (
    <Grid textAlign="center" flex={1} display={'flex'}>
      <Card
        elevation={0}
        sx={{
          borderRadius: BORDER_RADIUS,
          padding: 5,
          border: `1px solid ${BORDER_COLOR}`,
          maxWidth: maxWidth,
        }}
      >
        <Typography fontSize={LARGE_FONT}>{title}</Typography>
        <Grid container direction="column" spacing={2} paddingTop={1}>
          {boldText && (
            <Grid item>
              <Typography fontSize={SMALL_FONT} fontWeight="bold">
                {boldText}
              </Typography>
            </Grid>
          )}
          {description && (
            <Grid item>
              <Typography fontSize={SMALL_FONT}>{description}</Typography>
            </Grid>
          )}
          {linkSrc && linkText && makeLink(linkText, linkSrc)}
        </Grid>
      </Card>
    </Grid>
  );
};

export default LinkCard;
