import { useEffect } from 'react';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { useNavigate } from 'react-router-dom';

const SignupPage: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useAuth0();

  useEffect(() => {
    trackPostSignup();
    navigate('/onboarding');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Notifies Google Tag Manager when a user has signed up
  const trackPostSignup = () => {
    const googleAnalytics = analytics({
      app: 'Playground',
      plugins: [
        googleTagManager({
          containerId: 'GTM-5R859R5S',
        }),
      ],
    });

    googleAnalytics.page();

    googleAnalytics.track('postSignupEvent', {
      email: user?.email,
    });
  };

  return <></>;
};

export default SignupPage;
