import { useAuth0 } from '@auth0/auth0-react';
import { Grid, useMediaQuery } from '@mui/material';
import Header from 'components/Header';
import useBrowserSnackbars from 'hooks/useBrowserSnackbars';
import FAQ from 'pages/FAQPage';
import HomePage from 'pages/HomePage';
import LandingPage from 'pages/LandingPage';
import OnboardingPage from 'pages/OnboardingPage';
import PageNotFound from 'pages/PageNotFound';
import PlaygroundV2Page from 'pages/PlaygroundV2Page';
import PostPaymentPage from 'pages/PostPaymentPage';
import PostSignupPage from 'pages/PostSignupPage';
import PricingPage from 'pages/PricingPage';
import SignupPage from 'pages/SignupPage';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ISMOBILE_BREAKPOINT, LIGHT_GREY } from 'utils/theme';

const App = () => {
  // display loading page while Auth0 is authenticating the user
  const location = useLocation();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const { mobileInfoShown, browserMessageShown } = useBrowserSnackbars();

  useEffect(() => {
    mobileInfoShown();
  }, [mobileInfoShown]);

  useEffect(() => {
    browserMessageShown();
  }, [browserMessageShown]);

  const ProtectedRoute = ({ isAllowed, children, returnTo }) => {
    if (!isAllowed) {
      loginWithRedirect({
        appState: {
          returnTo: returnTo,
        },
      });
    }

    return children ? children : <PageNotFound />;
  };

  const isPlaygroundV2 = location.pathname === '/playgroundV2';

  return (
    <Grid sx={isPlaygroundV2 ? { background: LIGHT_GREY } : {}}>
      <Grid
        container
        direction="column"
        minWidth={isMobile ? 'auto' : '1200px'}
        width={isMobile || isPlaygroundV2 ? 'auto' : '85vw'}
        margin="auto"
      >
        {!isPlaygroundV2 && <Header />}
        <Grid
          item
          px={isMobile ? 3 : 0}
          container={isPlaygroundV2}
          justifyContent={isPlaygroundV2 ? 'center' : 'auto'}
        >
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/postpayment" element={<PostPaymentPage />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute isAllowed={isAuthenticated} returnTo="/home">
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/postsignup"
              element={
                <ProtectedRoute
                  isAllowed={isAuthenticated}
                  returnTo="/postsignup"
                >
                  <PostSignupPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute
                  isAllowed={isAuthenticated}
                  returnTo="onboarding"
                >
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/playgroundV2"
              element={
                <ProtectedRoute
                  isAllowed={isAuthenticated}
                  returnTo="/playgroundV2"
                >
                  <PlaygroundV2Page />
                </ProtectedRoute>
              }
            />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default App;
