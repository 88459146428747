import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import CheckboxGroup from 'components/CheckboxGroup';
import { ChangeEvent } from 'react';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';
import { sourceTypeValues } from '.';
import { useAppSelector } from 'core/store/hooks';

const SourceType = ({
  onChangeType,
}: {
  onChangeType: (checkedItems: string[]) => void;
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const { selectedSources } = useAppSelector((state) => state.sourceFilter);

  const handleChangeTypeValues = (
    event: ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    onChangeType(
      event.target.checked
        ? [...selectedSources, item]
        : selectedSources.filter((value) => item !== value)
    );
  };

  const handleChangeTypeValuesAll = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeType(event.target.checked ? sourceTypeValues : []);
  };

  return (
    <Stack>
      <Typography p={0} variant="subtitle1" pl={isMobile ? 0 : 0.5}>
        Type of source
      </Typography>
      <Box pl="0.5em">
        <CheckboxGroup
          checkboxes={sourceTypeValues}
          handleChange={handleChangeTypeValues}
          handleChangeAll={handleChangeTypeValuesAll}
          checkedValues={selectedSources}
        />
      </Box>
    </Stack>
  );
};

export default SourceType;
