import React from 'react';
import { Stack, Typography } from '@mui/material';
import { DARK_GREY, MEDIUM_FONT } from 'utils/theme';

interface SourceLinkProps {
  searchEngine?: string;
}

const SourceLink = (props: SourceLinkProps) => {
  return (
    <>
      {props.searchEngine && (
        <Stack direction={'row'}>
          <Typography
            color={DARK_GREY}
            fontSize={MEDIUM_FONT}
            textTransform="capitalize"
            pr={1}
          >
            {'Source'}
          </Typography>
          <Typography
            fontWeight={'bold'}
            fontSize={MEDIUM_FONT}
            textTransform={'capitalize'}
          >
            {props.searchEngine == 'ElasticSearch'
              ? 'FactiSearch'
              : props.searchEngine}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default SourceLink;
