import { BG_COLOR, DISPUTED, SUPPORTED, MIXED } from 'utils/theme';

export const useEvidenceLabel = () => {
  const MIXED_MIN = 0.4;
  const MIXED_MAX = 0.6;
  const USE_MIXED = true;

  const getLabel = (source: Source) => {
    const score = source.softmaxScore[1];
    switch (true) {
      case score <= MIXED_MIN:
        return 'Disputing';
      case USE_MIXED && score > MIXED_MIN && score < MIXED_MAX:
        return 'Mixed';
      case score >= MIXED_MAX:
        return 'Supporting';
      default:
        return 'Unkown';
    }
  };

  const getColor = (source: Source) => {
    const support = getLabel(source);
    switch (support) {
      case 'Disputing':
        return DISPUTED;
      case 'Mixed':
        return USE_MIXED ? MIXED : BG_COLOR;
      case 'Supporting':
        return SUPPORTED;
      default:
        return BG_COLOR;
    }
  };

  return { getLabel, getColor };
};
