import { Editor } from 'tinymce';
import { MIXED, DISPUTED, ENTITY, FOUNDCLAIM, SUPPORTED } from '../theme';
import { HighlightAs } from 'components/Editor';

/**
 * TinyMCE saves the editor text as HTML. This function returns the content
 * including the markup.
 *
 * @param {Editor} editor The TinyMCE editor
 * @return {string} The content of the editor as HTML
 */
export const getContentAsHtml = (editor: Editor) => {
  return editor.getContent();
};

/**
 * TinyMCE saves the editor text as HTML. This function returns the content
 * as plain text.
 *
 * @param {Editor} editor The TinyMCE editor
 * @return {string} The content of the editor as plain text
 */
export const getContentAsPlainText = (editor: Editor) => {
  return editor.getContent({ format: 'text' });
};

/**
 * Replaces the content with the given text.
 *
 * @param {Editor} editor The TinyMCE editor
 * @param {string} newContent The new content as HTML or plain text
 */
export const setContent = (editor: Editor, newContent: string) => {
  editor.setContent(newContent);
};

/**
 * Highlights the first occurence of the given text within the editor.
 *
 * @param {Editor} editor The TinyMCE editor
 * @param {string} toHighlight The text to highlight
 * @param {string} highlightAs The type of highlighting to be applied
 */
export const highlight = (
  editor: Editor,
  toHighlight: string,
  highlightAs: HighlightAs
) => {
  let before = '<span style="background-color:';
  switch (highlightAs) {
    case HighlightAs.Supported:
      before += SUPPORTED + '">';
      break;

    case HighlightAs.Disputed:
      before += DISPUTED + '">';
      break;

    case HighlightAs.Mixed:
      before += MIXED + '">';
      break;

    case HighlightAs.Entity:
      // TODO: use different color for Microfacts
      before += ENTITY + '">';
      break;

    case HighlightAs.FoundClaim:
      // TODO: update color
      before += FOUNDCLAIM + '">';
      break;

    default:
      return;
  }
  const after = '</span>';
  const newContent = wrapWithHTML(
    editor.getContent(),
    toHighlight,
    before,
    after
  );
  editor.setContent(newContent);
};

/**
 * Clears any highlighting within the text.
 *
 * @param {Editor} editor The TinyMCE editor
 */
export const clearHighlighting = (editor: Editor) => {
  const currentContent = getContentAsHtml(editor);
  const regex = /<\/?span[^>]*>/gi;
  const newContent = currentContent.replace(regex, '');
  editor.setContent(newContent);
};

export const getPosition = (editor: Editor, toFind: string) => {
  if (editor === null) return;
  const content = editor.getContent({ format: 'text' });
  return content.indexOf(toFind);
};

export function wrapWithHTML(
  html: string,
  toFind: string,
  before: string,
  after: string
): string {
  // Helper function to create a temporary document to parse HTML
  const createTempDocument = (html: string): Document => {
    const parser = new DOMParser();
    return parser.parseFromString(html, 'text/html');
  };

  // Helper function to recursively search and wrap text within nodes
  const wrapTextInNode = (
    node: Node,
    toFind: RegExp,
    before: string,
    after: string
  ) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        const text = child.nodeValue;
        if (text) {
          const html = text.replace(toFind, `${before}$&${after}`);
          if (html !== text) {
            const fragment = document
              .createRange()
              .createContextualFragment(html);
            child.parentNode?.replaceChild(fragment, child);
          }
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        wrapTextInNode(child, toFind, before, after); // Recurse into children
      }
    });
  };

  const doc = createTempDocument(html);

  const escapeRegExp = (toEscape: string) => {
    // Escaping special characters for use in a regular expression
    return toEscape.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const toFindRegexp = new RegExp(
    escapeRegExp(toFind).replace(/\s+/g, '\\s*(?:<[^>]+>\\s*)*'),
    'gi'
  );
  wrapTextInNode(doc.body, toFindRegexp, before, after);

  return doc.body.innerHTML;
}
