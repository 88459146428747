import { Button } from '@mui/material';
import { LIGHT_GREY, YELLOW } from 'utils/theme';

interface YellowBorderButtonProps {
  children: string | JSX.Element;
  onClick: () => void;
}

const YellowBorderButton = (props: YellowBorderButtonProps) => {
  const { children, onClick } = props;
  return (
    <Button
      sx={{
        border: `2px solid ${YELLOW}`,
        borderRadius: '20px',
        color: 'black',
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: LIGHT_GREY,
        },
        paddingX: '1em',
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default YellowBorderButton;
