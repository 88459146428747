import { Box, Stack, Typography, keyframes } from '@mui/material';
import { useEffect, useState } from 'react';

const dotFlashing = keyframes` 
  0% {
    background-color: #f8c609;
  }

  50%,100% {
    background-color: #ffe275;
  }
`;

export interface LoadingProps {
  labels: string[];
}

const Loading = (props: LoadingProps) => {
  const { labels } = props;
  const [currentLabel, setCurrentLabel] = useState(labels[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex < labels.length) {
          setCurrentLabel(labels[nextIndex]);
          return nextIndex;
        } else {
          clearInterval(timer);
          return prevIndex;
        }
      });
    }, 6000);

    return () => clearInterval(timer);
  }, [labels, currentIndex]);

  const DotComponent = ({ animationTime }: { animationTime: number }) => (
    <Box
      sx={{
        background: '#ffe275',
        borderRadius: '50%',
        animation: `${dotFlashing} 1s infinite alternate`,
        animationDelay: `${animationTime}s`,
      }}
      height="8px"
      width="8px"
    />
  );

  const dots = [0, 0.5, 1];

  return (
    <Stack flexDirection="row" alignItems="center">
      <Stack flexDirection="row" width="35px" justifyContent="space-between">
        {dots.map((dot, key) => {
          return <DotComponent key={key} animationTime={dot} />;
        })}
      </Stack>
      <Typography variant="subtitle1" ml={1} sx={{ color: 'grey' }}>
        {currentLabel}
      </Typography>
    </Stack>
  );
};

export default Loading;
