import { useAuth0 } from '@auth0/auth0-react';
import DiamondIcon from '@mui/icons-material/Diamond';
import { Stack, Typography } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import useUserMetaData from 'hooks/useUserMetaData';
import { useNavigate } from 'react-router-dom';
import { YELLOW } from 'utils/theme';

const ManageSubscription = () => {
  const { user } = useAuth0();
  const { noSubscription, trialSubscription, proSubscription } =
    useUserMetaData();
  const navigate = useNavigate();

  return (
    <Stack>
      {noSubscription || trialSubscription ? (
        <YellowBorderButton
          onClick={() => {
            navigate('/pricing');
          }}
        >
          <DiamondIcon sx={{ fontSize: '1.5rem' }} />
        </YellowBorderButton>
      ) : (
        <Stack>
          <Typography
            fontSize="1rem"
            color={YELLOW}
            fontWeight="bold"
            pb={2}
            align="center"
          >
            {proSubscription ? 'Factiverse Pro' : 'Factiverse Lite'}
          </Typography>

          <YellowBorderButton
            onClick={() => {
              window.open(
                'https://payment.factiverse.no/p/login/8wM6skdVk4bza9G144?prefilled_email=' +
                  user?.email,
                '_blank'
              );
            }}
          >
            <DiamondIcon sx={{ fontSize: '1.5rem' }} />
          </YellowBorderButton>
        </Stack>
      )}
    </Stack>
  );
};

export default ManageSubscription;
