import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';

const DateFilter = ({
  selectedDate,
  onClick,
}: {
  selectedDate: string;
  onClick: (value) => void;
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const DateButton = ({ value, label }: { value: string; label: string }) => (
    <Button
      variant="text"
      size="small"
      color="inherit"
      sx={{
        fontWeight: selectedDate === value ? 700 : 300,
        color: selectedDate === value ? 'rgba(0, 0, 0, 0.87)' : 'darkgrey',
        marginRight: '5px',
        textTransform: 'capitalize',
        fontSize: '0.875rem',
        p: 0,
        '&:hover': {
          background: 'none',
        },
      }}
      onClick={() => onClick(value)}
    >
      {label}
    </Button>
  );

  return (
    <Grid item xs={isMobile ? 12 : 5} pb={isMobile ? 2 : 0}>
      <Typography p={0} variant="subtitle1">
        Date published
      </Typography>
      <Stack flexDirection="row">
        <DateButton value={'mostRecent'} label="Most recent" />
        <Typography pr={1} variant="subtitle1">
          /
        </Typography>
        <DateButton value={'leastRecent'} label="Least recent" />
      </Stack>
    </Grid>
  );
};

export default DateFilter;
