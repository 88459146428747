import { Box, Typography } from '@mui/material';
import SortingAccordion from './SortingAccordion';

const ClaimSummary = ({ claim }: { claim: Claim }) => (
  <Box
    sx={{
      background: 'white',
      px: 4,
      py: 3,
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      mb: 2,
      mt: 3,
    }}
  >
    <Typography pb={1} fontSize="18px">
      {claim.claim}
    </Typography>
    <SortingAccordion />
  </Box>
);

export default ClaimSummary;
