import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import TutorialWrapper from './components/Tutorial/TutorialWrapper';
import { store, persistor } from './core/store/store';
import { Auth0ProviderWithNavigate } from './utils/Auth/Auth0ProviderWithNavigate';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';
import { theme } from './utils/theme';
import SettingsWrapper from 'utils/SettingsWrapper';
import { PersistGate } from 'redux-persist/integration/react';

Sentry.init({
  dsn: 'https://fc664229c085976752d6516bbc455eee@o4507537794859008.ingest.de.sentry.io/4507582145888336',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container as Element);

root.render(
  // Strict mode enabled as recommended for development, doesn't affect production
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* Use if deployed to Github Pages: <Router basename="/template-react"> */}

        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          maxSnack={3}
          autoHideDuration={3000}
        >
          <TutorialWrapper>
            <Router>
              <Auth0ProviderWithNavigate>
                <ThemeProvider theme={theme}>
                  <SettingsWrapper>
                    <App />
                  </SettingsWrapper>
                </ThemeProvider>
              </Auth0ProviderWithNavigate>
            </Router>
            <SnackbarUtilsConfigurator />
          </TutorialWrapper>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
