import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { ISMOBILE_BREAKPOINT, YELLOW } from 'utils/theme';

const OnboardingItem = ({
  onboardingQuestion,
  questionValues,
  onClick,
  selectedValues,
  onChangeOtherValue,
  otherValue,
}: {
  onboardingQuestion: string;
  questionValues: string[];
  onClick: (value: string) => void;
  selectedValues: string[];
  onChangeOtherValue: (event: ChangeEvent<HTMLInputElement>) => void;
  otherValue: string[];
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const OnboardingButton = ({
    children,
    onClick,
    selected,
  }: {
    children: string;
    onClick: () => void;
    selected: string[];
  }) => {
    return (
      <Button
        sx={{
          border: '1px solid grey',
          color: 'black',
          '&:hover': {
            borderColor: YELLOW,
            backgroundColor: '#fcedc2',
          },
          borderColor: selected.includes(children) ? YELLOW : 'black',
          fontSize: isMobile ? '12px' : '14px',
          width: '100%',
          lineHeight: isMobile ? 1 : 1.75,
          textTransform: 'none',
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  };

  return (
    <Grid
      item
      xs={12}
      pb={isMobile ? 1 : 3}
      container
      justifyContent="space-between"
    >
      <Grid item pb={3}>
        <Typography variant={isMobile ? 'body1' : 'h6'}>
          {onboardingQuestion}
        </Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'} color="GrayText">
          Select all that apply
        </Typography>
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="space-between"
        gap="15px"
      >
        {questionValues.map((v, key) => (
          <Grid key={key} item xs={5} container>
            {v === 'Other' ? (
              <TextField
                id="outlined-basic"
                label="Add other"
                variant="outlined"
                style={{ width: 'inherit' }}
                onChange={onChangeOtherValue}
                value={otherValue}
                InputProps={{
                  style: {
                    width: 'inherit',
                    borderColor: 'black',
                  },
                }}
              />
            ) : (
              <OnboardingButton
                selected={selectedValues}
                onClick={() => onClick(v)}
                key={key}
              >
                {v}
              </OnboardingButton>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OnboardingItem;
