import { useAppSelector } from 'core/store/hooks';

export const useClaimChecks = (claims: Claim[]) => {
  // loading state for claim detection
  const isFindingClaims = useAppSelector(
    (state) => state.claims.isFindingClaims
  );
  // claim detection returned nothing -> display message
  const noClaimsFound = useAppSelector((state) => state.claims.noClaimsFound);
  // loading state for stance detection
  const areClaimsBeingChecked = claims.some((claim) => claim.isBeingChecked);
  // stance detection returned with evidence
  const wasClaimEvidenceFound = claims.some((claim) => claim.evidence?.length);
  const isClaimsArrayEmpty = claims.length === 0;
  // loading state of claims and stance detection
  const isCheckingText = isFindingClaims || areClaimsBeingChecked;

  return {
    wasClaimEvidenceFound,
    isClaimsArrayEmpty,
    areClaimsBeingChecked,
    isFindingClaims,
    noClaimsFound,
    isCheckingText,
  };
};

export default useClaimChecks;
