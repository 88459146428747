import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  databaseFilterValues,
  sourceTypeValues,
} from 'components/FilterPopover';

interface AppState {
  selectedSources: string[];
  selectedSourceDate: string;
  selectedDatabases: string[];
}

const initialState: AppState = {
  selectedSources: sourceTypeValues,
  selectedSourceDate: 'mostRecent',
  selectedDatabases: databaseFilterValues,
};

const sourceFilterSlice = createSlice({
  name: 'sourceFilter',
  initialState,
  reducers: {
    setSelectedSources: (state: AppState, action: PayloadAction<string[]>) => ({
      ...state,
      selectedSources: action.payload,
    }),
    setSelectedSourceDate: (
      state: AppState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      selectedSourceDate: action.payload,
    }),
    setSelectedDatabases: (
      state: AppState,
      action: PayloadAction<string[]>
    ) => ({
      ...state,
      selectedDatabases: action.payload,
    }),
  },
});

export const {
  setSelectedSources,
  setSelectedSourceDate,
  setSelectedDatabases,
} = sourceFilterSlice.actions;
export default sourceFilterSlice.reducer;
