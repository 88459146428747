import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

interface ClaimDetectionResponse {
  detectedClaims: Array<Claim>;
}

/**
 * Send a claim detection request to the api
 *
 * @param {string} text The text to be checked for claims
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function claimDetectionRequest(
  text: string,
  logging: boolean
): Promise<AxiosResponse<ClaimDetectionResponse>> {
  const api = getAxiosInstance();
  return api.post('claim_detection', {
    text: text,
    lang: '',
    logging: logging,
    // TODO: set claimScoreThreshold depending on language, 0.75 default for English but might have to be lower for others
  });
}
