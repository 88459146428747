import {
  Box,
  Stack,
  Button as MuiButton,
  Typography,
  Button,
  List,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import SubscriptionCard from 'components/SubscriptionCard/SubscriptionCard';
import { ISMOBILE_BREAKPOINT, LIGHT_GREY } from 'utils/theme';

const StyledToggleButton = styled(MuiButton)({
  border: '0',
  padding: '5px',
  color: 'black',
  '&.Mui-selected, &.Mui-selected:hover, &.MuiToggleButton-root:hover': {
    backgroundColor: 'transparent',
  },
});

type ToggleButtonProps = {
  alignment: string;
  label: string;
  onChange: (newSelection: string) => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  alignment,
  label,
  onChange,
}) => {
  return (
    <StyledToggleButton
      value="monthly"
      aria-label="left aligned"
      disableRipple
      onClick={() => {
        onChange(label);
      }}
    >
      <Box
        px={4}
        py={1}
        sx={{
          backgroundColor: alignment == label ? 'white' : 'none',
          borderRadius: '5px',
        }}
      >
        <Typography>{label}</Typography>
      </Box>
    </StyledToggleButton>
  );
};

const PricingTable = () => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const [interval, setInterval] = React.useState<'monthly' | 'yearly'>(
    'monthly'
  );

  const handleChange = (newInterval: string) => {
    // only set when a different button is clicked (newAlignment is null if the currently selected button is clicked again)
    if (newInterval != null)
      setInterval(newInterval == 'monthly' ? 'monthly' : 'yearly');
  };

  return (
    <Stack
      alignItems="center"
      spacing={4}
      m="32px"
      p="40px"
      sx={{ backgroundColor: 'rgb(248, 196, 8)', borderRadius: '20px' }}
    >
      <Box
        aria-label="subscription interval"
        sx={{ borderRadius: '5px', backgroundColor: 'rgb(0,0,0,0.08)' }}
      >
        <ToggleButton
          alignment={interval}
          label="monthly"
          onChange={handleChange}
        />
        <ToggleButton
          alignment={interval}
          label="yearly"
          onChange={handleChange}
        />
      </Box>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={10}>
        <SubscriptionCard
          tier="Lite"
          subtitle="Find mistakes in text"
          price={interval == 'monthly' ? '10€' : '99€'}
          discount={interval == 'monthly' ? undefined : '17%'}
          interval={interval}
          featureList={[
            'Find and verify claims',
            'Discover varied sources',
            '3000 character limit per request',
          ]}
        />
        <SubscriptionCard
          tier="Pro"
          subtitle="Take full advantage of our features"
          price={interval == 'monthly' ? '25€' : '239€'}
          discount={interval == 'monthly' ? undefined : '20%'}
          interval={interval}
          featureList={[
            'Find and verify claims automatically',
            'Discover unlimited sources',
            'ChatGPT integration',
            '10000 character limit per request',
          ]}
        />
        <Stack spacing={2} maxWidth="288px">
          <Typography variant="h5" fontWeight="bold">
            Per seat
          </Typography>
          <Typography variant="subtitle1">5 seats minimum</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            pt={2}
            sx={{ visibility: 'hidden' }}
          >
            <Typography variant="h4" fontWeight="bold">
              Negotiable
            </Typography>
          </Stack>
          <Button
            sx={{
              backgroundColor: 'white',
              color: 'black',
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: LIGHT_GREY,
              },
            }}
            href="mailto:info@factiverse.ai"
          >
            Contact us
          </Button>
          <Typography variant="body1">This includes:</Typography>
          <List sx={{ listStyleType: 'disc', paddingLeft: '2em' }} dense>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="body1">
                Access for your whole team
              </Typography>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PricingTable;
