import { Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import Claims from 'components/Claims';
import { BORDER_COLOR, BORDER_RADIUS, ISMOBILE_BREAKPOINT } from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';

const Sidebar = () => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const claims = useAppSelector((state) => state.claims.claims);

  return (
    <div>
      {claims.length !== undefined ? (
        <Box
          sx={{
            borderTopRightRadius: BORDER_RADIUS,
            borderTopLeftRadius: BORDER_RADIUS,
            backgroundColor: 'white',
            marginTop: isMobile ? '1vh' : '0vh',
            border: `1px solid ${BORDER_COLOR}`,
            maxWidth: isMobile ? '94vw' : 'initial',
            marginLeft: '1vw',
          }}
          id="claims"
        >
          <Claims claims={claims} />
        </Box>
      ) : (
        <Grid item xs>
          <Box
            sx={{
              '& .MuiSkeleton-rounded': {
                borderTopLeftRadius: '36px',
                borderTopRightRadius: '36px',
              },
            }}
          >
            <Skeleton variant="rounded" height="80vh" />
          </Box>
        </Grid>
      )}
    </div>
  );
};

export default Sidebar;
