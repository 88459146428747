import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const randomHints = [
  'You can check facts in over 40 different languages',
  'AI Editor finds credible sources on Google, Bing, Semantic Scholar and Wikipedia',
  'AI Editor is trained on over 200.000 fact-checks',
  'You can check any textual content in 100 languages!',
  'AI Editor ranks results only by relevance and credibility',
  "You can select and analyze a claim manually if it wasn't detected automatically",
  'AI Editor can make mistakes, check the sources carefully',
  'Our model is evaluating each source based on historical credibility.',
  'We believe the best AI products are built for human-in-the-loop engagement',
  'Factiverse claim detection outperforms Mistral7b and GPT4',
  'Factiverse is built on high-quality training data from certified fact-checking organizations',
  'Factiverse always looks for the most credible references in real-time',
  'We find relevant fact-checks with FactiSearch (300k+ fact-checks)',
];

const RandomHint = () => {
  const [randomHint, setRandomHint] = useState<string>('');

  const getRandomIndex = () => {
    return Math.floor(Math.random() * randomHints.length);
  };

  useEffect(() => {
    const randomIndex = getRandomIndex();
    setRandomHint(randomHints[randomIndex]);
  }, []);

  return (
    <Stack direction="row" alignItems="center">
      <EmojiObjectsIcon sx={{ pr: 1 }} />
      <Typography variant="subtitle2">{randomHint}</Typography>
    </Stack>
  );
};

export default RandomHint;
