import { Stack } from '@mui/material';
import {
  ClaimEvidenceBody,
  ClaimEvidenceFooter,
  ClaimEvidenceHeader,
} from './ClaimEvidenceComponents';

const ClaimEvidence = ({
  evidence,
  index,
}: {
  evidence: Source;
  index: number;
}) => {
  return (
    <Stack
      key={index}
      sx={{
        background: 'white',
        borderRadius: '12px',
        boxShadow: '4px 4px 17px -3px rgba(128,155,181,0.14)',
        height: '100%',
      }}
      py={1}
      px={4}
      justifyContent="space-between"
    >
      <ClaimEvidenceHeader index={index} evidence={evidence} />
      <ClaimEvidenceBody evidence={evidence} />
      <ClaimEvidenceFooter evidence={evidence} />
    </Stack>
  );
};

export default ClaimEvidence;
