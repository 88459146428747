import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import Favicon from 'components/Favicon';
import SeeMoreButton from 'components/SeeMoreButton';
import moment from 'moment';
import Sum from 'components/Sum';
import { BORDER_COLOR } from 'utils/theme';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';

export const ClaimEvidenceHeader = ({
  index,
  evidence,
}: {
  index: number;
  evidence: Source;
}) => {
  return (
    <Typography fontSize="18px" my={2}>
      {index + 1}. {evidence.title}
    </Typography>
  );
};

export const ClaimEvidenceBody = ({ evidence }: { evidence: Source }) => {
  return (
    <Box
      sx={{
        background: 'rgba(241, 242, 251, 0.6)',
        padding: 3,
        borderRadius: '8px',
        marginY: 1,
      }}
    >
      <SeeMoreButton
        name={evidence.title ?? ''}
        text={evidence.evidenceSnippet ?? ''}
      />
    </Box>
  );
};

export const ClaimEvidenceFooter = ({ evidence }: { evidence: Source }) => {
  const { getColor, getLabel } = useEvidenceLabel();
  return (
    <Stack direction="row" p={1} alignItems="center" spacing="10px">
      <>
        <Sum color={getColor(evidence)} isSum={true}></Sum>
        <Typography
          fontSize="13px"
          pr="10px"
          borderRight={`1px solid ${BORDER_COLOR}`}
        >
          {getLabel(evidence)}
        </Typography>
      </>
      {evidence.domain && (
        <Favicon rootDomain={evidence.domain} width={24} height={24} />
      )}
      <Link
        sx={{
          pr: '10px',
          borderRight: `1px solid ${BORDER_COLOR}`,
          fontSize: '14px',
        }}
        href={evidence.url}
        target="_blank"
      >
        {evidence.domainName}
      </Link>
      {evidence.publishDate && (
        <Typography fontSize="13px" borderRight={`1px solid ${BORDER_COLOR}`}>
          {moment(evidence.publishDate).year()}
        </Typography>
      )}
      <Typography fontSize="13px">{evidence.searchEngine}</Typography>
    </Stack>
  );
};
