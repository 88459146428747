import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

interface FeedbackResponse {
  status: boolean;
}

/**
 * Send feedback on claim credibility to the api
 *
 * @param {string} claim The claim to give feedback on
 * @param {string} email The user's email address
 * @param {boolean} userAgrees True if the user confirms the credibility assessment
 * @param {language} language The language of the claim
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackCredibilityRequest(
  claim: string,
  email: string,
  userAgrees: boolean,
  language: string,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  const api = getAxiosInstance();
  return api.post('feedback', {
    feedbackType: 'claimStance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userAgrees,
      lang: language,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source relevance to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {string} email The user's email address
 * @param {boolean} userIsRelevant True if the user confirms the relevance of the source
 * @param {language} language The language of the claim
 * @param {object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackSourceRelevanceRequest(
  claim: string,
  email: string,
  userIsRelevant: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  const api = getAxiosInstance();
  return api.post('feedback', {
    feedbackType: 'sourceRelevance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userIsRelevant,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source support to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {string} email The user's email address
 * @param {boolean} userAgrees True if the user confirms the support of the source
 * @param {language} language The language of the claim
 * @param {object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackSourceSupportRequest(
  claim: string,
  email: string,
  userAgrees: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  const api = getAxiosInstance();
  return api.post('feedback', {
    feedbackType: 'sourceStance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userAgrees,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}
