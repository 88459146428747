import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const ClaimSkeleton = () => {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Skeleton height="26px" width="26px" />
        <Skeleton height="26px" width="100%" />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton height="26px" width="26px" />
        <Skeleton height="26px" width="100%" />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton height="26px" width="26px" />
        <Skeleton height="26px" width="100%" />
      </Stack>
    </>
  );
};
export default ClaimSkeleton;
