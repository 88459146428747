import { Stack, Typography } from '@mui/material';
import { CheckSquare, XSquare } from '@phosphor-icons/react';
import React from 'react';
import { DARK_GREY } from 'utils/theme';

const ClaimHint: React.FC = () => {
  const ClaimHintItem = ({
    title,
    badExample,
    goodExample,
  }: {
    title: string;
    badExample: string;
    goodExample: string;
  }) => (
    <>
      <Typography variant="h6">{title}</Typography>
      <Stack direction="row" alignItems="center">
        <XSquare size={28} color={DARK_GREY} />
        <Typography pl={2} variant="body1" color={DARK_GREY}>
          {badExample}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <CheckSquare size={28} color={DARK_GREY} />
        <Typography pl={2} variant="body1" color={DARK_GREY}>
          {goodExample}
        </Typography>
      </Stack>
    </>
  );
  return (
    <Stack px={8} pt={6} sx={{ textAlign: 'center' }} spacing={2}>
      <Typography variant="h6" fontWeight="bold">
        Get the best results
      </Typography>
      <Stack sx={{ textAlign: 'left' }} spacing={2}>
        <ClaimHintItem
          title="Factual claims"
          badExample="Oranges taste good"
          goodExample="Oranges contain a lot of Vitamin C"
        />
        <ClaimHintItem
          title="Precise claims"
          badExample="Apples are better than oranges"
          goodExample="Apples contain less sugar than oranges"
        />
        <ClaimHintItem
          title="Textual claims"
          badExample="China produces 53,2% of apples worldwide"
          goodExample="China is the biggest apple producer"
        />
      </Stack>
    </Stack>
  );
};

export default ClaimHint;
