import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { API_CONFIG } from './../../config';
import { showError } from 'utils/SnackbarUtils';

let factiverseApi: AxiosInstance | null = null;
let auth0Client: Auth0Client | null = null;

export enum ErrorMsg {
  NoServerResp = 'We’re sorry! There seems to be an issue with your request. Please bear with us, most issues should be resolved soon.',
  ServerTimeout = 'Sorry, your request is taking longer than usual! Please try again in a bit or get in touch if the issue persists (info@factiverse.no).',
  StanceDetection422 = 'The text provided is malformed. Try changing the text or check out the example texts for inspiration.',
  StanceDetection422Lang = 'The language we detected is not supported. We are working on adding more languages. Please Check the FAQ for all supported languages.',
  ClaimDetection422 = 'The data provided is malformed. Try changing the text or check out the example texts for inspiration.',
  ClaimDetection422Lang = 'The language we detected is not supported. We are working on adding more languages. Please Check the FAQ for all supported languages.',
  Authentication = 'We’re sorry! There seems to be an issue with your authentication. Please try to log out and log in again.',
  TokenRefreshFailed = "We couldn't refresh your session. Please log in again.",
  AxiosNotInitialized = 'API client not initialized. Please try again or contact support if the issue persists.',
}

export const initializeAxios = (auth0: Auth0Client): AxiosInstance => {
  auth0Client = auth0;

  factiverseApi = axios.create({
    baseURL: API_CONFIG.apiBaseURL,
    timeout: 500000,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  factiverseApi.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      try {
        const token = await getAccessToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.error('Error fetching Auth0 token:', error);
        showError(ErrorMsg.Authentication);
      }
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  factiverseApi.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & {
        _retry?: boolean;
      };
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await auth0Client?.loginWithRedirect();
          const axiosInstance = getAxiosInstance();
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError);
          showError(ErrorMsg.TokenRefreshFailed);
        }
      }
      return Promise.reject(error);
    }
  );

  return factiverseApi;
};

const getAccessToken = async (): Promise<string | undefined> => {
  if (!auth0Client) {
    throw new Error('Auth0 client not initialized');
  }

  try {
    // First, try to get the token silently
    return await auth0Client.getTokenSilently();
  } catch (error) {
    console.error('Silent token acquisition failed:', error);

    // If silent acquisition fails, try to refresh the session
    try {
      await auth0Client.checkSession();
      return await auth0Client.getTokenSilently();
    } catch (refreshError) {
      console.error('Session refresh failed:', refreshError);
      throw refreshError;
    }
  }
};

export const getAxiosInstance = (): AxiosInstance => {
  if (!factiverseApi) {
    throw new Error(ErrorMsg.AxiosNotInitialized);
  }
  return factiverseApi;
};
