import { useAuth0 } from '@auth0/auth0-react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTour } from '@reactour/tour';
import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';
import BundledEditor from 'utils/TinyMCE/BundledEditor';

export enum HighlightAs {
  Supported = 'supported',
  Disputed = 'disputed',
  Mixed = 'mixed',
  FoundClaim = 'foundClaim',
  Entity = 'entity,',
}
interface EditorProps {
  initialValue: string;
  examples: SelectExamples[];
  onSelectExample: () => void;
  setEditorRef: (editor: TinyMCEEditor) => void;
  onClearResults: () => void;
  onClick?: () => void;
  onKeyDown?: () => void;
}

const Editor: React.FC<EditorProps> = ({
  initialValue,
  examples,
  onSelectExample,
  setEditorRef,
  onClearResults,
  onClick,
  onKeyDown,
}) => {
  const { user } = useAuth0();
  const { seenTutorial } = useSettings();

  const [isVisible, setIsVisible] = useState('hidden');

  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const { setIsOpen } = useTour();

  useEffect(() => {
    if (!user) return;
    if (!seenTutorial) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seenTutorial, user]);

  return (
    <>
      {isVisible === 'hidden' && (
        <Box
          sx={{
            '& .MuiSkeleton-rounded': {
              borderRadius: '10px',
            },
          }}
        >
          <Skeleton variant="rounded" height="80vh" width="inherit" />
        </Box>
      )}
      <Box
        id="editor"
        sx={{
          visibility: isVisible,
          maxWidth: isMobile ? '90vw' : 'inherit',
          '& .tox-promotion': {
            display: 'none',
          },
        }}
      >
        <BundledEditor
          apiKey="idzbxwizewsu11gskpg6bk4v0a10r6v7r4etbfhot27cv1w5"
          onInit={(evt, editor) => {
            setEditorRef(editor);
            setIsVisible('visible');
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          initialValue={initialValue}
          init={{
            highlight_on_focus: false,
            height: '70vh',
            license_key: 'gpl',
            plugins: ['searchreplace', 'autosave', 'wordcount'],
            toolbar: [
              'undo redo | bold italic underline | selectExample | clearText | tutorial',
            ],
            toolbar_mode: 'wrap',
            placeholder: 'Write or paste a text that you want to analyze...',
            elementpath: false,
            paste_as_text: true,
            auto_focus: true,
            removed_menuitems:
              'preview export deleteallconversations print visualaid image link media addcomment pageembed template codesample inserttable charmap emoticons hr pagebreak nonbreaking anchor tableofcontents insertdatetime spellchecker spellcheckerlanguage a11ycheck code wordcount inserttable cell row column advtablesort tableprops deletetable help',
            autosave_interval: '1s',
            autosave_prefix: 'previous-content-',
            autosave_ask_before_unload: false,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap'); body { font-family: DM Sans; }",
            setup: (editor) => {
              editor.ui.registry.addButton('clearText', {
                icon: 'remove',
                text: 'Clear',
                tooltip: 'Clear the text and results',
                onAction: () => {
                  editor.setContent('');
                  localStorage.setItem(
                    'previous-content-draft',
                    editor.getContent()
                  );
                  onClearResults();
                },
              });

              editor.ui.registry.addMenuButton('selectExample', {
                icon: 'copy-simple',
                text: 'Examples',
                tooltip: 'Select on of our example texts',
                fetch: (callback) => {
                  const formatExamples = () => {
                    const formattedExamples: unknown[] = [];
                    for (let i = 0; i < examples.length; i++) {
                      formattedExamples.push({
                        type: 'menuitem',
                        text: examples[i].name,
                        onAction: () => {
                          editor.setContent(examples[i].text);
                          onSelectExample();
                          localStorage.setItem(
                            'previous-content-draft',
                            editor.getContent()
                          );
                        },
                      });
                    }
                    return formattedExamples;
                  };
                  const items = formatExamples();
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  callback(items as any);
                },
              });

              editor.ui.registry.addButton('tutorial', {
                icon: 'help',
                tooltip: 'Open tutorial',
                onAction: () => {
                  setIsOpen(true);
                },
              });

              // icons from phosphoricons.com
              editor.ui.registry.addIcon(
                'copy-simple',
                '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M184,64H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V72A8,8,0,0,0,184,64Zm-8,144H48V80H176ZM224,40V184a8,8,0,0,1-16,0V48H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40Z"></path></svg>'
              );
            },
          }}
        />
      </Box>
    </>
  );
};

export default Editor;
