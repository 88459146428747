import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { API_CONFIG } from './../../config';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeAxios } from 'utils/API/apiClient';

export const Auth0ProviderWithNavigate: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const audience = API_CONFIG.audience;
  const scope = API_CONFIG.scope;

  const domain = API_CONFIG.domain;
  const clientId = API_CONFIG.clientId;
  const redirectUri = API_CONFIG.redirectUri;

  const onRedirectCallback = (appState?: { returnTo?: string }) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        scope: scope,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Auth0Initializer>{children}</Auth0Initializer>
    </Auth0Provider>
  );
};

const Auth0Initializer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const initAuth = async () => {
      if (isAuthenticated) {
        try {
          const auth0Client = new Auth0Client({
            domain: API_CONFIG.domain,
            clientId: API_CONFIG.clientId,
            authorizationParams: {
              audience: API_CONFIG.audience,
              scope: API_CONFIG.scope,
              redirect_uri: API_CONFIG.redirectUri,
            },
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
          });

          // Set the getTokenSilently method
          auth0Client.getTokenSilently = getAccessTokenSilently;

          // Initialize Axios with the auth0Client
          initializeAxios(auth0Client);
        } catch (error) {
          console.error('Failed to initialize Auth0 client or Axios:', error);
        }
      }
    };

    initAuth();
  }, [isAuthenticated, getAccessTokenSilently]);

  return <>{children}</>;
};
