export const faqs: FAQCategory[] = [
  {
    label: 'General',
    faqs: [
      {
        question: 'How does Factiverse AI Editor work?',
        answer:
          'Factiverse uses advanced machine learning to analyze text and identify factual statements. It then provides live sources that either support or dispute those statements. It is trained on articles from reputable news media and certified fact-checkers. ',
      },
      {
        question:
          'Can I trust that Factiverse AI Editor is telling me the truth?',
        answer:
          "Factiverse AI Editor is a machine learning model that assists you in your search for reliable information. However, it doesn't have inherent knowledge of what is true or false. It's designed to aid you in your quest for accurate information. It performs most accurately on news topics like Covid-19, climate change, US and EU politics. We believe in humans working with technology, not being replaced by it",
      },
      {
        question: 'Which languages does Factiverse AI Editor support?',
        answer:
          'Currently, Factiverse AI Editor can automatically identify claims only in English texts. However, you can manually check claims in over 90 languages using this tool. Simply highlight the text you want to check and click the “Analyze” button.\n\nAll supported languages are: Afrikaans, Amharic, Arabic, Assamese, Azerbaijani, Belarusian, Bulgarian, Bengali, Breton, Bosnian, Catalan, Czech, Welsh, Danish, German, Greek, English, Esperanto, Spanish, Estonian, Basque, Persian, Finnish, French, Western Frisian, Irish, Scottish Gaelic, Galician, Gujarati, Hausa, Hebrew, Hindi, Croatian, Hungarian, Armenian, Indonesian, Icelandic, Italian, Japanese, Javanese, Georgian, Kazakh, Khmer, Kannada, Korean, Kurdish, Kyrgyz, Latin, Lao, Lithuanian, Latvian, Malagasy, Macedonian, Malayalam, Mongolian, Marathi, Malay, Burmese, Nepali, Dutch, Norwegian, Oromo, Odia, Punjabi, Polish, Pashto, Portuguese, Romanian, Russian, Sanskrit, Sindhi, Sinhala, Slovak, Slovenian, Somali, Albanian, Serbian, Sundanese, Swedish, Swahili, Tamil, Telugu, Thai, Filipino, Turkish, Uighur, Ukrainian, Urdu, Uzbek, Vietnamese, Xhosa, Yiddish and Chinese.',
      },
      {
        question:
          'Does Factiverse AI Editor work with different types of texts, such as articles, blog posts, or research papers?',
        answer:
          "Factiverse AI Editor can analyze any type of text, but currently works best with news articles and well-covered topics. Are you missing a certain feature that can save you time and improve your content? Get in touch via <a href='mailto:info@factiverse.no'>info@factiverse.no</a> or join our <a href='https://discord.gg/fRXjgfGDev' target='_blank' rel='noreferrer'>Discord server</a>",
      },
      {
        question:
          'How frequently is Factiverse AI Editor updated with new information and sources?',
        answer:
          'Factiverse AI Editor can access the latest information and sources in real time. Everytime you check a claim or analyze a whole text, we do live searches in public databases such as Google, Bing, Wikidata and Wikipedia. Our machine learning then ranks these sources based on past credibility in a given topic. ',
      },
      {
        question:
          'Can Factiverse AI Editor handle claims made in audio, video or image formats?',
        answer:
          'Factiverse AI Editor currently focuses on analyzing and processing text-based content only.',
      },
      {
        question:
          'Does Factiverse AI Editor have any limitations or areas where it may struggle to accurately detect claims or find relevant sources?',
        answer:
          'Factiverse AI Editor may face challenges when dealing with numerical claims, as they can be difficult to interpret accurately.  We are working with the brightest minds at TU Delft on improving that. Our service also does best on claims that have between 3-10 words. Longer sentences may produce errors in our assessment. ',
      },
      {
        question: 'Is there an API or integration options available?',
        answer:
          "Yes, Factiverse offers an API for easy integration. If you would like to know more, please reach out to us via <a href='mailto:info@factiverse.no'>info@factiverse.no</a> or join our <a href='https://discord.gg/fRXjgfGDev' target='_blank' rel='noreferrer'>Discord server</a>",
      },
      {
        question:
          'Can Factiverse AI Editor be used offline, or does it require an internet connection?',
        answer:
          'While you can write and edit text offline, Factiverse AI Editor requires an internet connection to check claims and access relevant sources.',
      },
      {
        question:
          'Are there any specific legal considerations when using Factiverse AI Editor for fact-checking or source verification?',
        answer:
          "It's important to note that Factiverse AI Editor is still in development and testing. Factiverse AS does not provide any explicit or implicit warranties regarding the software's suitability or usability, or the accuracy of its content.",
      },
      {
        question:
          'Can Factiverse AI Editor be customized or adapted to specific needs or industries?',
        answer:
          "If you have specific needs or are interested in customizing AI Editor for your industry, please contact us at <a href='mailto:info@factiverse.no'>info@factiverse.no</a>.",
      },
    ],
  },
  {
    label: 'Claim Detection',
    faqs: [
      {
        question:
          'What is a claim and why is it important to identify them in text?',
        answer:
          'A claim is a statement that asserts a fact or belief. It is crucial to identify claims in text because it allows us to subject them to fact-checking and evaluate their accuracy. We have spent many years researching and developing our model to find these claims quickly.',
      },
      {
        question:
          'How does Factiverse AI Editor detect factual claims within a text?',
        answer:
          'Factiverse AI Editor utilizes a machine learning model that has been trained on various texts and claims. This model enables it to automatically identify and extract factual claims from the given text.',
      },
      {
        question:
          'How accurate is the machine learning algorithm in detecting factual claims?',
        answer:
          "Factiverse AI Editor simplifies the process of fact-checking text by automatically identifying numerous claims. While we continually strive to enhance claim detection accuracy, the algorithm's current performance aids in streamlining the fact-checking process.",
      },
    ],
  },
  {
    label: 'Source Discovery',
    faqs: [
      {
        question:
          'How can I evaluate the credibility of a claim with the help of Factiverse AI Editor?',
        answer:
          "Factiverse AI Editor provides a valuable resource by presenting an overview of relevant sources for each claim. These sources are categorized as either “supporting”, “neutral” or “disputing” based on the machine learning model's analysis.\n\nTo assess the credibility of a claim, you can visit these sources yourself and verify the information. Our team will be rolling out new features and options that enhance your experience during the summer, but let us know if you have any great ideas or suggestions.\nGet in touch via <a href='mailto:info@factiverse.no'>info@factiverse.no</a> or join our <a href='https://discord.gg/fRXjgfGDev' target='_blank' rel='noreferrer'>Discord server</a>",
      },
      {
        question: 'How reliable are the sources used by Factiverse AI Editor?',
        answer:
          "Factiverse offers a diverse range of sources to provide you with a comprehensive overview. However, it's important to note that the final decision regarding the reliability of these sources rests with you. It is always recommended to critically evaluate the sources independently to ensure their trustworthiness.",
      },
    ],
  },
  {
    label: 'Pricing',
    faqs: [
      {
        question: 'What is the pricing model for using Factiverse AI Editor?',
        answer:
          'Factiverse Editor is available for free during your 14 days free trial. Our current paid plan is at 10€ per month.',
      },
      {
        question: 'How do I resolve a billing issue?',
        answer:
          "If you encounter any billing issues or have problems related to payments, please reach out to us at <a href='mailto:info@factiverse.no'>info@factiverse.no</a>, and we will assist you in resolving the matter.",
      },
    ],
  },
  {
    label: 'Privacy',
    faqs: [
      {
        question: 'Do you store any of my data?',
        answer:
          'We use Plausible for user analytics and we store your queries in order to enhance the performance of the Factiverse AI Editor. We will never store or share any personal information internally or to 3rd parties.',
      },
      {
        question: 'Will you use my queries for training?',
        answer:
          'Yes, we may utilize your queries to further enhance the capabilities of AI Editor based on your valuable feedback. You can easily opt out from anonymous data sharing by clicking your profile icon in the Editor. ',
      },
    ],
  },
];

export const faqQuestionsData = [
  {
    title: 'Claims',
    linkText: 'Watch a tutorial',
    linkSrc: 'https://www.youtube.com/watch?v=rMBHHfn6mk0',
  },
];
