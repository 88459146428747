import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  claims: Claim[];
  isFindingClaims?: boolean;
  noClaimsFound: boolean;
}

const initialState: AppState = {
  claims: [],
  isFindingClaims: false,
  noClaimsFound: false,
};

const isEquals = (claimA: Claim, claimB: Claim) => {
  if (claimA.id !== undefined && claimB !== undefined)
    return claimA.id === claimB.id;
  return claimA.claim === claimB.claim;
};

const containsClaim = (claims: Claim[], claim: Claim): boolean => {
  let found = false;
  claims.forEach((currentClaim) => {
    if (currentClaim.claim.replace(/\s/g, '') == claim.claim.replace(/\s/g, ''))
      found = true;
  });
  return found;
};

const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    setClaims: (state: AppState, action: PayloadAction<Claim[]>) => ({
      ...state,
      claims: action.payload,
    }),
    addClaim: (state: AppState, action: PayloadAction<Claim>) => {
      if (containsClaim(state.claims, action.payload)) return state;
      return { ...state, claims: [...state.claims, action.payload] };
    },
    deleteClaim: (state: AppState, action: PayloadAction<Claim>) => ({
      ...state,
      claims: state.claims.filter((claim) => {
        if (claim.id == action.payload.id) return false;
        return true;
      }),
    }),
    updateClaim: (state: AppState, action: PayloadAction<Claim>) => ({
      ...state,
      claims: state.claims.map((claim) => {
        if (isEquals(claim, action.payload)) {
          return {
            ...claim,
            id: action.payload.id ?? claim.id,
            language: action.payload.language ?? claim.language,
            evidence: action.payload.evidence ?? claim.evidence,
            position: action.payload.position ?? claim.position,
            finalScore: action.payload.finalScore ?? claim.finalScore,
            // text that will have claims will have loading state here
            isBeingChecked:
              action.payload.isBeingChecked ?? claim.isBeingChecked,
            hasBeenChecked:
              action.payload.hasBeenChecked ?? claim.hasBeenChecked,
            summary: action.payload.summary ?? claim.summary,
            fix: action.payload.fix ?? claim.fix,
          };
        } else {
          return claim;
        }
      }),
    }),
    // text that will not have claims will have loading state here
    setIsFindingClaims: (state: AppState, action: PayloadAction<boolean>) => ({
      ...state,
      isFindingClaims: action.payload,
    }),
    setNoClaimsFound: (state: AppState, action: PayloadAction<boolean>) => ({
      ...state,
      noClaimsFound: action.payload,
    }),
    emptyClaims: (state: AppState) => ({
      ...state,
      claims: [],
      noClaimsFound: false,
    }),
  },
});

export const {
  setClaims,
  addClaim,
  deleteClaim,
  emptyClaims,
  updateClaim,
  setIsFindingClaims,
  setNoClaimsFound,
} = claimsSlice.actions;
export default claimsSlice.reducer;
