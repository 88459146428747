import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import ClaimEvidence from './components/ClaimEvidence';
import ClaimSummary from './components/ClaimSummary';

const Claim = () => {
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const sortedSources = useAppSelector((state) => state.claimsV2.sortedSources);

  return (
    <Box>
      <ClaimSummary claim={claim} />
      <Grid container rowSpacing={2} columnSpacing={2}>
        {sortedSources.map((source, index) => (
          <Grid item key={index} xs={12} xl={6} pb={2}>
            <ClaimEvidence index={index} evidence={source} key={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Claim;
