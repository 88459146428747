import { useMediaQuery } from '@mui/material';
import { useCallback } from 'react';
import { showInfo } from 'utils/SnackbarUtils';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';

export const useBrowserSnackbars = () => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const isChrome = !!window['chrome'];

  const displaySnackbarItem = (value, localStorageLabel, message) => {
    if (value) {
      setTimeout(() => {
        localStorage.setItem(localStorageLabel, 'true');
      }, 5000);
    }

    if (value && localStorage.getItem(localStorageLabel) === null) {
      showInfo(message, 5000);
    }
  };

  const mobileInfoShown = useCallback(() => {
    displaySnackbarItem(
      isMobile,
      'mobileInfoShown',
      'Please note that AI Editor provides the best experience on larger devices.'
    );
  }, [isMobile]);

  const browserMessageShown = useCallback(() => {
    displaySnackbarItem(
      !isChrome,
      'browserMessageShown',
      'Please note that AI Editor has been tested with Google Chrome and might have issues in other browsers'
    );
  }, [isChrome]);

  return { mobileInfoShown, browserMessageShown };
};

export default useBrowserSnackbars;
