import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';

interface AloowTrackingProps {
  allowTracking: boolean;
  setAllowTracking: (newValue: boolean) => void;
}

const AllowTracking: React.FC<AloowTrackingProps> = ({
  allowTracking,
  setAllowTracking,
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={allowTracking}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAllowTracking(event.target.checked);
            }}
          />
        }
        label={
          <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
            I agree with the{' '}
            <a href="https://factiverse.ai/terms-and-conditions" target="blank">
              Terms and Conditions
            </a>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default AllowTracking;
