import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { ISMOBILE_BREAKPOINT, YELLOW } from 'utils/theme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Sum from 'components/Sum';
import parser from 'html-react-parser';

interface FAQsProps {
  category: FAQCategory;
}

const FAQs: React.FC<FAQsProps> = ({ category }) => {
  const [expanded, setExpanded] = React.useState(
    Array(category.faqs.length).fill(false)
  );

  const toggleExpanded = (num: number) => {
    const newExpanded = [...expanded];
    newExpanded[num] = !newExpanded[num];
    setExpanded(newExpanded);
  };

  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const numberedQuestionBlock = (faq: FAQ, index: number) => {
    return (
      <Stack
        key={index}
        width={isMobile ? '90%' : '50%'}
        minHeight="50px"
        pb={2}
        spacing={1}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Stack direction="row" spacing={1}>
            <Sum color={YELLOW} count={index} isSum={false} />
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => toggleExpanded(index)}
              width="95%"
            >
              {faq.question}
            </Typography>
          </Stack>
          <IconButton onClick={() => toggleExpanded(index)}>
            {expanded[index] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Stack>
        {expanded[index] && (
          <>
            <Typography pl={4} sx={{ whiteSpace: 'pre-line' }}>
              {parser(faq.answer)}
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Typography variant="h4" pt={6} pb={2}>
        {category.label}
      </Typography>
      {category.faqs.map((faq, i) => {
        return numberedQuestionBlock(faq, i + 1);
      })}
    </>
  );
};

export default FAQs;
