import { useAppSelector } from 'core/store/hooks';

export const useScoreCount = () => {
  const evidence = useAppSelector((state) => state.claimsV2.claim.evidence);

  const supportingSources = evidence?.filter((item) => {
    return item.softmaxScore[1] >= 0.6;
  });

  const mixedSources = evidence?.filter((item) => {
    return item.softmaxScore[1] > 0.4 && item.softmaxScore[1] < 0.6;
  });

  const disputingSources = evidence?.filter((item) => {
    return item.softmaxScore[1] <= 0.4;
  });

  return {
    supportingSources,
    mixedSources,
    disputingSources,
  };
};

export default useScoreCount;
