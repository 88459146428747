import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';

export interface CheckboxGroupProps {
  /**
   * Each given string is a checkbox label
   */
  checkboxes: string[];
  handleChange: (event: ChangeEvent<HTMLInputElement>, item: string) => void;
  /**
   * Each given string sets a checkbox as selected, others are deselected
   */
  checkedValues: string[];
  handleChangeAll: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A list of checkboxes wich can be selected independently with an (optional) additional checkbox to select/deselect all options
 *
 * @return {JSX.Element}
 */
const CheckboxGroup = ({
  checkboxes,
  handleChange,
  checkedValues,
  handleChangeAll,
}: CheckboxGroupProps) => {
  return (
    <FormControl component="fieldset" variant="standard" margin="none">
      <FormGroup>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes
                    .map((c) => checkedValues.includes(c))
                    .every((v) => v === true)}
                  onChange={handleChangeAll}
                  size="small"
                  name="All"
                  sx={{
                    width: '50px',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                />
              }
              label={<Typography variant="subtitle2">All</Typography>}
            />
          </Grid>
          {checkboxes.map((checkbox, key) => (
            <Grid item xs={6} key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedValues?.includes(checkbox)}
                    onChange={(e) => handleChange(e, checkbox)}
                    name={checkbox}
                    size="small"
                    value={checkbox}
                    sx={{
                      width: '50px',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label={<Typography variant="subtitle2">{checkbox}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
