import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import LinkCard from 'components/LinkCard';
import QABlock from 'components/FAQs';
import { faqQuestionsData } from 'data/faq';
import { faqs } from 'data/faq';
import GDPR from 'assets/GDPR.pdf';

/**
 * The FAQ page, with short explainers, tutorials on features.
 *
 * @return {React.FC} The page component
 */
const FAQPage: React.FC = () => {
  const data = faqQuestionsData;

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      padding={1}
      paddingBottom="4em"
      sx={{
        backgroundImage:
          'radial-gradient(circle, #fef5d0, #fffbed, #fafbfc, #fafbfc, #fafbfc, #fafbfc)',
        backgroundPosition: 'center -150px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container direction="column" alignItems="center" padding={4}>
        <Typography variant="h4">Have any questions?</Typography>
      </Grid>
      <Stack justifyContent={'space-between'} spacing={4} direction={'row'}>
        {data.map((data, i) => {
          return (
            <LinkCard
              key={i}
              title={data.title}
              linkText={data.linkText}
              linkSrc={data.linkSrc}
            />
          );
        })}
      </Stack>
      {faqs.map((item, index) => {
        return <QABlock category={item} key={index} />;
      })}
      <a
        href={GDPR}
        download={'Factiverse AI Editor GDPR.pdf'}
        target="_blank"
        rel="noreferrer"
      >
        <Typography>GDPR notice</Typography>
      </a>
    </Stack>
  );
};

export default FAQPage;
