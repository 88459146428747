// ! only for testing

import chatGPTEssay from './chatGPTEssay.json';
import whatIsElNino from './whatIsElNino.json';
import healthyEating from './healthyEating.json';

interface ExampleClaim {
  id: number;
  claims: Claim[];
}
export const exampleClaims: Array<ExampleClaim> = [
  {
    id: 1,
    claims: whatIsElNino as Claim[],
  },
  {
    id: 2,
    claims: chatGPTEssay as Claim[],
  },
  {
    id: 3,
    claims: healthyEating as Claim[],
  },
];
