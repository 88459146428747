import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

interface StanceDetectionResponse {
  claim: string;
  _id: string;
  evidence: Array<Source>;
  finalPrediction: number;
  finalScore: number;
  summary: Array<string>;
  fix: string;
}

export const stanceDetectionRequest = (
  claim: string,
  logging: boolean,
  resolvedClaim?: string
): Promise<AxiosResponse<StanceDetectionResponse>> => {
  const api = getAxiosInstance();
  return api.post('stance_detection', {
    claim,
    lang: '',
    logging,
    resolved_claim: resolvedClaim,
    search_engines: [
      'Bing Search',
      'Google Claim',
      'Google',
      'Wikipedia',
      'Semantic Scholar',
      'ElasticSearch',
      'You.com',
    ],
  });
};
