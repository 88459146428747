import React from 'react';
import { Typography, Grid } from '@mui/material';
import { SMALL_FONT } from 'utils/theme';

interface SumProps {
  color: string;
  count?: number;
  isSum?: boolean;
}

const Sum = (props: SumProps) => {
  const { color, count, isSum } = props;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background: isSum ? color : 'none',
        height: '26px',
        width: count === undefined || count < 100 ? '26px' : 'auto',
        borderRadius: '8px',
        border: isSum ? 'none' : `2px solid ${color}`,
      }}
    >
      <Grid item>
        <Typography
          fontSize={SMALL_FONT}
          align="center"
          fontWeight="bold"
          color="black"
        >
          {count}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Sum;
