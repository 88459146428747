// ! only for testing
import { v4 as uuidv4 } from 'uuid';

export const exampleText1 =
  'VAERS data does not prove thousands died from receiving COVID-19 vaccines\n\n' +
  'Vaccine Adverse Event Reporting System (VAERS) claims that thousands ' +
  'of people died from receiving COVID-19 vaccines. The video, which fails ' +
  'to mention that anyone can report events to VAERS and that the database ' +
  'contains unverified information, describes reported deaths of ' +
  'individuals who died after receiving the vaccine as deaths caused by ' +
  'the vaccine, when the Centers for Disease Control and Prevention (CDC) ' +
  'has so far found no evidence that vaccinations led to patient deaths.\n\n' +
  'The video in question, shared over 1,700 times at the time of this ' +
  'publication and is titled "COVID19 VACCINES DEATH TOLL CLIMBING!!!". ' +
  'It shows a person scrolling through a VAERS dataset of death reports ' +
  'and saying, "This is the list of all the people who have died from ' +
  'vaccines". The narrator clicks on random entries, including a ' +
  '38-year-old in New Mexico and a 65-year-old in Ohio who were both ' +
  'given the Pfizer-BioNTech vaccine, before saying "the vaccine itself ' +
  'will kill you".\n\n' +
  'According to the latest data, 1,985 U.S. deaths of individuals who ' +
  'died after receiving at least one dose of the COVID-19 vaccines have ' +
  'been reported to VAERS. Of these, 1,016 are listed as receiving doses ' +
  'from Moderna, 946 as Pfizer-BioNTech, 16 as Janssen, and seven as ' +
  '"unknown manufacturer". In total, 1,579, or nearly early 80%, of these ' +
  'individuals were over the age of 65.\n\n' +
  'Reports of death to VAERS following vaccination do not necessarily mean ' +
  'the vaccine caused the death.\n\n' +
  'Reports of death to VAERS following vaccination mean the vaccine caused ' +
  'the death.\n\n' +
  'Of the 145 million COVID-19 vaccine doses administered in the United ' +
  'States from Dec. 14, 2020 through March 29, 2021, "VAERS received ' +
  '2,509 reports of death (0.0017%) among people who received a COVID-19 ' +
  'vaccine". Having reviewed "available clinical information including ' +
  'death certificates, autopsy, and medical records," the CDC found "no ' +
  'evidence that vaccination contributed to patient deaths".\n\n' +
  'Dr. Dana Mazo, an assistant professor of medicine who specializes in ' +
  'infectious diseases at Mount Sinai Hospital in New York, stressed to ' +
  'Reuters via phone the importance of distinguishing between association ' +
  'and causation when looking at post-vaccine death records.\n\n' +
  '"The idea is that we are vaccinating millions of people," Mazo said, ' +
  '"and, unfortunately, when you look at 96 million people, some of them ' +
  'might die, and they would have died if they hadn\'t been vaccinated".';

export const covidMythsEn =
  "<h2>Let's set the record straight on some of the myths circulating about COVID-19 vaccines.</h2><br/><br/>" +
  '<b>The COVID-19 vaccine is not safe because it was rapidly developed and tested.</b><br/>' +
  "Many pharmaceutical companies invested significant resources into quickly developing a vaccine for COVID-19 because of the world-wide impact of the pandemic. The emergency situation warranted an emergency response but that does not mean that companies bypassed safety protocols or didn't perform adequate testing.<br/>" +
  'Mayo Clinic will recommend the use of those vaccines that we are confident are safe. While there are many COVID-19 vaccine candidates in development, early interim data are encouraging for the Pfizer vaccine which likely is to be the first authorized for emergency use by the FDA in the late December/early January timeframe. This vaccine was created using a novel technology based on the molecular structure of the virus. The novel methodology to develop a COVID-19 vaccine allows it to be free from materials of animal origin and synthesized by an efficient, cell-free process without preservatives. This vaccine developed by Pfizer/BioNTecH has been studied in approximately 43,000 people.<br/>' +
  'To receive emergency use authorization, the biopharmaceutical manufacturer must have followed at least half of the study participants for at least two months after completing the vaccination series, and the vaccine must be proven safe and effective in that population. In addition to the safety review by the FDA, the Advisory Committee on Immunization has convened a panel of vaccine safety experts to independently evaluate the safety data from the clinical trial. Mayo Clinic vaccine experts also will review the available data. The safety of COVID-19 vaccine will continue to be closely monitored by the Centers for Disease Control and Prevention (CDC) and the FDA.<br/><br/>' +
  "<b>I already had COVID-19 and I have recovered, so I don't need to get a COVID-19 vaccine when it's available.</b><br/>" +
  'There is not enough information currently available to say if or for how long after infection someone is protected from getting COVID-19 again. This is called natural immunity. Early evidence suggests natural immunity from COVID-19 may not last very long, but more studies are needed to better understand this. Mayo Clinic recommends getting the COVID-19 vaccine, even if you’ve had COVID-19 previously. However, those that had COVID-19 should delay vaccination until about 90 days from diagnosis. People should not get vaccinated if in quarantine after exposure or if they have COVID-19 symptoms.<br/><br/>' +
  '<b>There are severe side effects of the COVID-19 vaccines.</b><br/>' +
  'There are short-term mild or moderate vaccine reactions that resolve without complication or injury. The early phase studies of the Pfizer vaccine show that it is safe. About 15% of people developed short lived symptoms at the site of the injection. 50% developed systemic reactions primarily headache, chills, fatigue or muscle pain or fever lasting for a day or two. Keep in mind that these side effects are indicators that your immune system is responding to the vaccine and are common when receiving vaccines.<br/><br/>' +
  "<b>I won't need to wear a mask after I get vaccinated for COVID-19.</b><br/>" +
  'It may take time for everyone who wants a COVID-19 vaccination to get one. Also, while the vaccine may prevent you from getting sick, it is unknown at this time if you can still carry and transmit the virus to others. Until more is understood about how well the vaccine works, continuing with precautions such as mask-wearing and physical distancing will be important.<br/>' +
  'Until more is understood about how well the vaccine works, continuing with precautions, such as wearing a mask and practicing physical distancing, will be important.<br/><br/>' +
  '<b>More people will die as a result of a negative side effect to the COVID-19 vaccine than would actually die from the virus.</b><br/>' +
  "Circulating on social media is the claim that COVID-19's mortality rate is 1%-2% and that people should not be vaccinated against a virus with a high survival rate. However, a 1% mortality rate is 10 times more lethal than the seasonal flu. In addition, the mortality rate can vary widely and is influenced by age, sex and underlying health condition.<br/>" +
  'While some people that receive the vaccine may develop symptoms as their immune system responds, remember that this is common when receiving any vaccine and not considered serious or life-threatening. You cannot get COVID-19 infection from the COVID-19 vaccines; they are inactivated vaccines and not live viruses.<br/>' +
  "It's important to recognize that getting the vaccine is not just about survival from COVID-19. It's about preventing spread of the virus to others and preventing infection that can lead to long-term negative health effects. While no vaccine is 100% effective, they are far better than not getting a vaccine. The benefits certainly outweigh the risks in healthy people.<br/><br/>" +
  '<b>The COVID-19 vaccine was developed to control the general population either through microchip tracking or "nanotransducers" in our brains.</b><br/>' +
  'There is no vaccine microchip, and the vaccine will not track people or gather personal information into a database.<br/>' +
  'This myth started after comments made by Bill Gates from The Gates Foundation about a digital certificate of vaccine records. The technology he was referencing is not a microchip, has not been implemented in any manner and is not tied to the development, testing or distribution of COVID-19 vaccines.<br/><br/>' +
  '<b>COVID-19 vaccines will alter my DNA.</b><br/>' +
  'The first COVID-19 vaccines to reach the market are likely to be messenger RNA (mRNA) vaccines. According to the CDC, mRNA vaccines work by instructing cells in the body how to make a protein that triggers an immune response. Injecting mRNA into your body will not interact or do anything to the DNA of your cells. Human cells break down and get rid of the mRNA soon after they have finished using the instructions.<br/><br/>' +
  '<b>COVID-19 vaccines were developed using fetal tissue.</b><br/>' +
  'Neither the Pfizer/BioNTech COVID-19 vaccine nor the Moderna COVID-19 vaccines contain fetal cells nor were fetal cells used the development or production of either vaccine.<br/><br/>' +
  '<b>COVID-19 vaccines cause infertility or miscarriage.</b><br/>' +
  'No, COVID-19 vaccines have not been linked to infertility or miscarriage.</br></br>' +
  'https://www.mayoclinichealthsystem.org/hometown-health/featured-topic/covid-19-vaccine-myths-debunked';

export const covidMythsEnClaims: Claim[] = [
  {
    id: uuidv4(),
    claim: 'COVID-19 vaksine kan endre menneskelig DNA',
  },
  {
    id: uuidv4(),
    claim:
      'The COVID-19 vaccine is not safe because it was rapidly developed and tested.',
  },
  {
    id: uuidv4(),
    claim:
      'Many pharmaceutical companies invested significant resources into quickly developing a vaccine for COVID-19 because of the world-wide impact of the pandemic.',
  },
  {
    id: uuidv4(),
    claim:
      'This vaccine was created using a novel technology based on the molecular structure of the virus.',
  },
  {
    id: uuidv4(),
    claim:
      'The novel methodology to develop a COVID-19 vaccine allows it to be free from materials of animal origin and synthesized by an efficient, cell-free process without preservatives.',
  },
  {
    id: uuidv4(),
    claim:
      'This vaccine developed by Pfizer/BioNTecH has been studied in approximately 43,000 people.',
  },
  {
    id: uuidv4(),
    claim:
      'In addition to the safety review by the FDA, the Advisory Committee on Immunization has convened a panel of vaccine safety experts to independently evaluate the safety data from the clinical trial.',
  },
  {
    id: uuidv4(),
    claim:
      "I already had COVID-19 and I have recovered, so I don't need to get a COVID-19 vaccine when it's available.",
  },
  {
    id: uuidv4(),
    claim:
      'There is not enough information currently available to say if or for how long after infection someone is protected from getting COVID-19 again.',
  },
  {
    id: uuidv4(),
    claim:
      'Mayo Clinic recommends getting the COVID-19 vaccine, even if you’ve had COVID-19 previously.',
  },
  {
    id: uuidv4(),
    claim: 'There are severe side effects of the COVID-19 vaccines.',
  },
  {
    id: uuidv4(),
    claim:
      'The early phase studies of the Pfizer vaccine show that it is safe.',
  },
  {
    id: uuidv4(),
    claim:
      'About 15% of people developed short lived symptoms at the site of the injection.',
  },
  {
    id: uuidv4(),
    claim:
      'More people will die as a result of a negative side effect to the COVID-19 vaccine than would actually die from the virus.',
  },
  {
    id: uuidv4(),
    claim:
      "Circulating on social media is the claim that COVID-19's mortality rate is 1%-2% and that people should not be vaccinated against a virus with a high survival rate.",
  },
  {
    id: uuidv4(),
    claim:
      'However, a 1% mortality rate is 10 times more lethal than the seasonal flu.',
  },
  {
    id: uuidv4(),
    claim:
      'You cannot get COVID-19 infection from the COVID-19 vaccines; they are inactivated vaccines and not live viruses.',
  },
  {
    id: uuidv4(),
    claim:
      'The COVID-19 vaccine was developed to control the general population either through microchip tracking or "nanotransducers" in our brains.',
  },
  {
    id: uuidv4(),
    claim:
      'There is no vaccine microchip, and the vaccine will not track people or gather personal information into a database.',
  },
  {
    id: uuidv4(),
    claim:
      'This myth started after comments made by Bill Gates from The Gates Foundation about a digital certificate of vaccine records.',
  },
  {
    id: uuidv4(),
    claim:
      'The technology he was referencing is not a microchip, has not been implemented in any manner and is not tied to the development, testing or distribution of COVID-19 vaccines.',
  },
  {
    id: uuidv4(),
    claim:
      'The first COVID-19 vaccines to reach the market are likely to be messenger RNA (mRNA) vaccines.',
  },
  {
    id: uuidv4(),
    claim:
      'According to the CDC, mRNA vaccines work by instructing cells in the body how to make a protein that triggers an immune response.',
  },
  {
    id: uuidv4(),
    claim:
      'Human cells break down and get rid of the mRNA soon after they have finished using the instructions.',
  },
  {
    id: uuidv4(),
    claim: 'COVID-19 vaccines were developed using fetal tissue.',
  },
  {
    id: uuidv4(),
    claim:
      'Neither the Pfizer/BioNTech COVID-19 vaccine nor the Moderna COVID-19 vaccines contain fetal cells nor were fetal cells used the development or production of either vaccine.',
  },
  {
    id: uuidv4(),
    claim: 'COVID-19 vaccines cause infertility or miscarriage.',
  },
  {
    id: uuidv4(),
    claim:
      'No, COVID-19 vaccines have not been linked to infertility or miscarriage.',
  },
];

export const covidMythsNo =
  '<h2>Ekspertene svarer: Ti påstander om koronaviruset</h2>' +
  '<b>Når vi hører at de som er vaksinert likevel blir smittet, så er det ingen vits i vaksineringen.</b><br/>' +
  'For det første kan vaksiner gi flere nivåer av beskyttelse. Selv immunforsvaret ikke fullstendig klarer å blokkere viruset fra å trenge inn i cellene våre, er det trolig at vi har fått kroppen vår til å håndtere viruset på en bedre måte. Altså, vaksinene kan potensielt beskytte mot alvorlig sykdom og død til tross for at de ikke gir fullstendig beskyttelse mot sykdom, sier hun og legger til.<br/>' +
  'Folk er forskjellige, og vaksinene vil i noen tilfeller rett og slett ikke fungere optimalt. Det er da viktig at vi får vaksinert så mange som mulig, for å få en flokkimmunitet. Det begrenser eller hindrer viruset fra å spre seg effektivt mellom mennesker, og på den måten danne et beskyttende skjold for oss alle sammen.<br/>' +
  'Preben Aavitsland fra FHI på sin side understreker at det er få, om noen ting, ingen legevitenskapen som det kan settes to streker under svaret på.<br/>' +
  'Ingen vaksiner beskytter 100 %. Det vil derfor forekomme sykdom også hos vaksinerte, men de vaksinerte har mye lavere risiko for å bli syke, sier han.<br/>' +
  'Vaksinene som er i bruk i Norge i dag hindrer alvorlig sykdom hos mer enn 90 % av de vaksinerte viser foreløpige tall. Det er også dokumentert at de hindrer smittespredning. Her kan du lese mer om det.<br/><br/>' +
  '<b>I løpet av kort vil det komme nye virusvarianter som gjør at vaksinen ikke virker og vi må stenge ned samfunnet igjen.</b><br/>' +
  'Gunnveig Grødeland forsker på nettopp dette og forklarer hvordan det kan se ut i fremtiden:' +
  'Vi vet ikke ennå i hvilken grad vaksinene vil beskytte mot nye varianter, men det er trolig at vaksinene (eller smitte) vil kunne gi en viss grad av beskyttelse også mot nye varianter, sier hun.<br/>' +
  'Aavitsland understreker at vaksinene kan endres raskt dersom det er behov for å møte nye mutasjoner.<br/>' +
  'Vaksinene vi benytter i Norge, virker bra mot alle kjente virusvarianter. Dersom det dukker opp varianter som vaksinasjon ikke beskytter mot, kan en ny dose med en litt endret vaksine være nok til å beskytte oss videre., sier han.<br/>' +
  'Og faktisk er vaksineprodusentene allerede i gang med utprøving av nye varianter av vaksinene som treffer nye varianter dersom de skulle bli dominerende.<br/>' +
  'Det betyr ikke nødvendigvis at man må stenge ned samfunnet igjen hvis man gir påfyll av vaksine før effekten av gamle vaksiner blir for dårlig. Det er litt sånn det fungerer med influensavaksinene. Vi må forholde oss til det og planlegge godt slik at vi er mest mulig i forkant av utviklingen, sier Espen Nakstad' +
  'Vaksineutviklerne vil derfor alltid være bak utviklingen av viruset. Men målet er å ligg ett skritt bak – ikke ti skritt.<br/><br/>' +
  '<b>Koronaviruset lever i luften og flytter seg over lange avstander. Det hjelper ikke å holde avstand til andre eller ha strenge tiltak, man kan bli smittet hvor som helst.</b><br/>' +
  'Assisterende helsedirektør Espen Nakstad forklarer avstanden på to meter.<br/>' +
  'At det er smittefare hvor som helst, selv om man holder seg langt unna folk, er et argument som brukes både av personer som vil bli kvitt tiltakene og av personer som vil ha strengere tiltak.<br/>' +
  'Men at viruset skal kunne reise lange avstander i luften (eller sågar i atmosfæren som enkelte hevder) er helt usannsynlig hvis vi spør fagpersonene:' +
  'Selv om viruset kan overleve litt tid på ulike overflater rundt oss, er dette veldig begrenset. Primær smittevei er andre personer rundt oss, og jo lengre avstand, jo lavere sannsynlighet for å bli smittet, sier Grødeland' +
  'Erfaringene er at avstand hjelper betydelig. Det kan likevel skje smitte over flere meters avstand innendørs i trange rom. Det er derfor fornuftig å redusere nær kontakt mellom mennesker, for man kan aldri helt sikkert vite hvem som er smittet uten selv å vite det, sier Aavitsland.<br/>' +
  'Nakstad er tydelig på at avstand har en god effekt, men minner om at de nye virusvariantene gjør at man må være enda mer forsiktig innendørs.<br/>' +
  'Man blir sjelden smittet hvis man holder god avstand til andre eller bruker munnbind ved trengsel. Utendørs er smitterisikoen tilnærmet ikke-eksisterende hvis avstanden er tilstrekkelig. Innendørs er det derimot økt smitterisiko med de nye muterte virusvariantene hvis mange personer er til stede over lang tid og ventilasjonen er dårlig. Det skyldes at det trenges lavere «dose», for å bli smittet med disse variantene, sier han.<br/><br/>' +
  '<b>Hvis vi stengte alle kommuner eller de delene av kommunene som har smitte til all smitte er borte, så kunne de stedene uten smitte holde helt åpent.</b><br/>' +
  'En utfordring med en slik strategi er at man må handle svært raskt lokalt og derfor er avhengig av å oppdage smitte veldig fort. Man vil måtte åpne og lukke deler av samfunnet på veldig kort varsel, forklarer assisterende helsedirektør Espen Rostrup Nakstad.<br/>' +
  'Overlege ved FHI, Preben Aavitsland er enig.<br/>' +
  'Kanskje er det teoretisk mulig, men neppe i praksis siden vi er avhengig av å få fraktet varer inn i landet, og mange reisende har viktige grunner for å kunne reise ut og inn av kommuner og landet.<br/>' +
  'Den største utfordringen for en slik strategi er altså rett og slett at samfunnet er avhengig av en viss grad av mobilitet og forutsigbarhet.<br/><br/>' +
  '<b>Hadde vi stengt grensene helt og ikke åpnet opp i sommer, hadde Norge vært helt smittefritt i dag</b><br/>' +
  'De aller fleste importtilfellene kom på grunn av reiser til land med høyt smittenivå, som var klassifisert som «røde».<br/>' +
  'I følge FHIs ukerapport for uke 31 i fjor, så var 67 av 290 oppdagede smittetilfeller i Norge antatt smittet i utlandet. 15 av disse var smittet i et «grønt» land.<br/>' +
  'To uker senere, i uke 33 hadde andelen utenlandssmittede sunket betydelig, selv om smitten økte i Norge. Av 730 meldte tilfeller var 131 antatt smittet i utlandet. 26 av disse ble smittet i et land som ikke hadde karantenekrav ved innreise til Norge.<br/>' +
  'Etter høstferien begynte det å øke drastisk. Det skyldes nok flere ting; dels mange innreiser fra land i Øst-Europa som på dette tidspunktet hadde fått en dramatisk smittevekst, dels redusert etterlevelse av karantenebestemmelser i Norge blant innreisende personer fra røde land, forklarer Espen Nakstad.<br/>' +
  'Pandemier lar seg kun midlertidig løse på nasjonalt plan. Selv om vi hadde sittet i vår egen lille smittefrie enklave her oppe i nord ville vi vært utsatt siden grensene ikke kan være hermetisk lukket, sier Gunnveig Grødeland.<br/>' +
  'Også Aavitsland ved FHI er tvilende til en slik påstand.<br/>' +
  'Et forsøk på å holde viruset borte fra landet ville ha krevd enorme ressurser til grensekontroll og karantenehotell.<br/><br/>' +
  '<b>Viruset er ikke så smittsomt som helsemyndighetene hevder. Vår sønn ble smittet, men smittet ingen hjemme hos oss.</b><br/>' +
  'Det er mye som spiller inn her. Blant annet grad av viruseksponering, altså rett og slett hvor mange viruspartikler får man inn i kroppen. Så har eget immunforsvar noe å si. Og når det gjelder stedet man oppholder seg, så kan avstand, ventilasjon og renhold ha noe å si.<br/>' +
  'Det første spørsmålet er da om man er sikker på at ingen andre hjemme faktisk ble smittet. Det er mulig at andre medlemmer av familien hadde immunresponser som ble dannet mot andre koronavirus, sier Gunnveig Grødeland.<br/>' +
  'Det er mulig at de faktisk aldri fikk virus fra den smittede. Kanskje den som var smittet i liten grad hostet dem i fjeset? Kanskje den som var smittet ikke hadde så mye virus i luftveiene? Mange muligheter. Det å trekke konklusjoner basert på enkeltopplevelser fører ingensteds hen, understreker hun.<br/>' +
  'Preben Aavitsland legger til at de aller fleste faktisk ikke smitter noen videre.<br/>' +
  'Erfaringen er at de fleste smittede ikke smitter noen andre, men at noen smitter én, to, tre eller flere andre. For familiemedlemmer er risikoen i gjennomsnitt rundt 15–20 %, sier han.<br/><br/>' +
  '<b>Viruset er ikke ekte. Det er noe verdens politikere har funnet på for å holde kontroll på oss.</b><br/>' +
  'Espen Nakstad kjenner godt til at dette er en holdning som enkelte fremsetter og mener den faller på sin egen urimelighet.<br/>' +
  'Viruset påvises i tusenvis av mikrobiologiske laboratorier i hele verden hver eneste dag, helt uavhengig av hverandre. Det er rapportert millioner av positive prøver så langt i pandemien, over 120.000 av dem i Norge. Virusets opphav diskuteres fortsatt, men det er ingen tvil om at det har spredt seg i hele verden. Alt er godt dokumentert og tilgjengelig i medisinske tidsskrifter og i rapporter fra fagmyndigheter i mange land, sier Nakstad.<br/>' +
  'Preben Aavitsland ber skeptikerne se til sykehusets intensivavdelinger.<br/>' +
  'Ta en prat med de ansatte på intensivavdelingen på nærmeste sykehus og hør om deres erfaringer med dette viruset, sier han. Mens Gunnveig Grødeland kort og godt svarer:' +
  'Nei!</br></br>' +
  'https://www.nrk.no/norge/fakta-og-myter-om-koronaviruset-faktasjekk-med-eksperter-pa-virus-1.15479503';

// https://ng.opera.news/ng/en/food/0642a04add4692eb52b2fae9e14222e1
export const foodMythsEn =
  '<h2>Common Myths About Food and Nutrition</h2><br/><br/>' +
  '<b>Fat makes you fat.</b><br/>' +
  'Fat gets such a bad rap that we often forget there are such things as healthy fats, such as those found in nuts, olive oil, salmon, and avocados, says Nicole Ferring Holovach. Yes, fat grams do contain more calories than carbs or proteins, but fat is still an essential component of our diet. Adds Elise Museles: They “keep you satiated by slowing down the digestion process so you stay full for a longer period of time.”<br/><br/>' +
  '<b>Eating more protein will lead to bigger muscles.</b><br/>' +
  '“A myth perpetuated in gyms!” Webb says. “While eating protein is important, eating more than you need is unnecessary. Resistance training and exercises in which you use your body weight as resistance, such as yoga, can lead to a more defined look. But eating a ton a protein is not going to lead to large muscular development.”<br/><br/>' +
  '<b>Muscle weighs more than fat.</b><br/>' +
  '“Unless the laws of physics have changed, one pound is one pound is one pound,” says Elana Natker. “The difference is that muscle is denser than fat, so one pound of muscle takes up less space than a pound of fat.”<br/><br/>' +
  '<b>Egg yolks are bad for you.</b><br/>' +
  'Don’t waste those yolks anymore—they’re a “goldmine of nutrition,” says Holovach. One yolk contains half of your day’s requirement of choline, which is an essential nutrient for the brain. Plus recent research shows that dietary cholesterol doesn’t affect blood cholesterol levels as much as previously thought, Holovach adds. Eggs for breakfast will fill you up with protein and fat and will keep you from overeating the rest of the day.<br/><br/>' +
  '<b>Eight glasses of water a day is the magic number.</b><br/>' +
  'Just like with nutrients and calories, basic hydration needs varies for each individual, says Heather Calcote. How much water you need to drink daily depends on your exercise and activity level, and even the temperature of where you live. A person needs to learn to recognize thirst and drink water both with meals and in between meal times as needed. “Remember that things like tea, coffee, soup, and most fruits also contribute to water intake, but be mindful of added sugars, caffeine, and sodium.”<br/><br/>' +
  '<b>To lose weight, avoid indulging.</b><br/>' +
  '“Healthy eating and healthy living is all about balance,” says Stephanie Mull, so there’s always room for most foods in one’s diet. “People who restrict too much create psychological connections to those forbidden foods, causing them to overeat when they do consume them.”<br/><br/>' +
  '<b>Eating after 7 PM will make you gain weight.</b><br/>' +
  '“You don’t magically store more fat after 7 PM,” says Danielle Omar. “What and how much you eat will determine weight gain or loss.” To lose weight, try spreading out your calories throughout the day, so you’re not starving in the evening and end up overeating, she suggests.<br/><br/>' +
  '<b>Carrots are high in sugar, so you should avoid them.</b><br/>' +
  'Carrots are more than 85 percent water, and one pound of cooked carrots only has three teaspoons of sugar. In fact, since they’re high in phytochemicals such as beta carotene and fiber, eating them will actually help lower blood sugar, Omar says.<br/><br/>' +
  '<b>Babies sleep better and longer if you give them formula before bed.</b><br/>' +
  'It’s an old wives’ tale, says Natker. In fact, formula can cause an upset stomach in some babies, which would certainly keep them up at night. A good night’s rest really depends on a baby’s size, daily sleep patterns, and temperament.<br/><br/>' +
  '<b>Eating a product labeled gluten-free is healthy.</b><br/>' +
  'Gluten-free is a hot trend in the world of nutrition, but it’s not for everyone, says Museles. While those who jump on the gluten-free bandwagon even if they don’t suffer from celiac disease often feel better, it’s most likely because they’ve eliminated processed foods from their diet. Eating naturally gluten-free foods such as quinoa, sweet potatoes, and millet is great, but gluten-free processed food is not necessarily a healthy choice.<br/><br/>';

export const healthMythsEn =
  '<h2>Common Health Myths</h2><br/><br/>' +
  '<b>Cracking your fingers causes arthritis.</b><br/>' +
  'To be sure, cracking your fingers is no way to make friends in a quiet library. But the habit itself won’t give you arthritis — at least not according to clinical studies, including one way back in 1990 and one more recently in 2011, specifically focused on addressing this myth.<br/>' +
  'Arthritis develops when the cartilage within the joint breaks down and allows the bones to rub together. Your joints are surrounded by a synovial membrane, which contains synovial fluid that lubricates them and prevents them from grinding together.<br/>' +
  'When you crack your knuckles, you’re pulling your joints apart. This stretch causes an air bubble to form in the fluid, which eventually pops, creating that familiar sound.<br/>' +
  'Cracking your knuckles isn’t necessarily good for you, though.<br/>' +
  'While there’s no proven relationship between the habit and arthritis, persistent cracking can wear down your synovial membrane and make it easier for your joints to crack. It can also lead to hand swelling and weaken your grip.<br/><br/>' +
  '<b>Going out with wet hair gets you sick.</b><br/>' +
  'This myth is dangerously logical. You’ve just scrubbed yourself clean, and you’ve got a head of cold, wet hair — you’ve never been more exposed to the germs and viruses flying around in the air outside.<br/>' +
  'It turns out, though, that leaving the house just after a shower isn’t going to make you sick… unless you’re already sick, that is.<br/>' +
  'In 2005, researchers tested the hypothesis that chilling your body increases your chances of being infected with the common cold virus, also known as acute viral nasopharyngitis.<br/>' +
  'Their results found that, no, it doesn’t. But it can cause the onset of symptoms if the virus is already in your body.<br/>' +
  'So if you’re afraid that you might be sick but have a very important meeting tomorrow, you may want to blow-dry your hair before you leave the house.<br/><br/>' +
  '<b>Dirty toilet seats can transmit STDs.</b><br/>' +
  'Unkempt gas station bathrooms might be the site of your worst nightmares, but it’s highly unlikely (though not impossible) that they’ll give you a sexually transmitted disease (STD).<br/>' +
  'STDs can be caused by viruses, bacteria, or parasites. Only parasitic STDs like crabs (pubic lice) or trichomoniasis have any real chance of being transmitted by sitting on a dirty toilet seat. And even then, the likelihood is extremely low.<br/>' +
  'Your genital area would need to come into contact with the toilet seat while the parasite is still on it, and alive — and toilet seats don’t provide ideal living situations for parasites.<br/>' +
  'Exercise a little common sense: Use a toilet seat cover, and don’t linger.<br/><br/>' +
  '<b>Antiperspirants and deodorants can cause cancer.</b><br/>' +
  'It has long been claimed that antiperspirants and deodorants contain harmful, cancer-causing substances, like parabens and aluminum, which can be absorbed by your skin when you use them. But the research simply doesn’t back this up.<br/>' +
  'The National Cancer Institute says that there is no known evidence that these chemicals can cause cancer, and the Food and Drug Administration has similarly dispelled the notion that parabens can affect estrogen levels, and thus lead to cancer.<br/><br/>' +
  '<b>Drinking alcohol in any amount dumbs you down.</b><br/>' +
  'Alcohol, when misused, can impair your judgment and seriously affect your health.<br/>' +
  'This is why the U.S. Department of Health and Human Services recommends limiting your intake to just two drinks per day for men, and one drink for women. However, alcohol isn’t all bad for the brain, at least according to some research.<br/>' +
  'One 2015 study found that drinking small to moderate amounts doesn’t alter cognitive ability, working memory, or motor skills in young adults.<br/>' +
  'And among middle-aged adults, older research found that drinking more actually improved some cognitive functions, including vocabulary and accumulated information (although they did ponder whether social factors also played a role).<br/>' +
  'The takeaway does appear to be that, so long as you don’t abuse alcohol, it is unlikely to do much damage to your brain.<br/><br/>' +
  'https://www.healthline.com/health/7-health-myths-that-are-actually-false';

export const climateMythsEn =
  '<h2>Myths about climate change</h2><br/><br/>' +
  '<b>Solar changes are causing the earth to warm, not us.</b><br/>' +
  'The sun powers life on earth, keeping the planet warm enough for us all to survive. The sun also influences earth’s climate. Subtle changes in earth’s orbit around the sun were responsible for past ice ages.<br/>' +
  "But the global warming we’ve seen over the last few decades is too rapid and dramatic to be linked to changes in earth’s orbit, and too large to be caused by solar activity. For more than 40 years, satellites have observed the sun's energy output, which has gone up or down by less than 0.1 percent during that period, while global warming has increased.<br/>" +
  'The United Nations’ Intergovernmental Panel on Climate Change (IPCC) concluded that warming from increased levels of human-produced greenhouse gases is actually many times stronger than any climate change effects due to the sun.<br/>' +
  'NASA’s conclusion is even more dramatic; since 1750, the warming driven by greenhouse gases from our burning of fossil fuels is over 50 times greater than the slight extra warming coming from the sun itself over that same time interval.<br/><br/>' +
  '<b>It is too late to stop climate change.</b><br/>' +
  'This is a critical time to take action on climate change. And it can be done.<br/>' +
  'We already have the technology and systems to achieve net zero carbon emissions by 2050. We can halt climate change and create a sustainable future for humans and nature.<br/>' +
  'Energy companies, industrial and vehicle manufacturers and governments have an essential part to play in achieving net zero.<br/><br/>' +
  '<b>Climate change is a future problem.</b><br/>' +
  'This is no longer an excuse not to act on climate change and push the burden onto future generations. Last year, the world’s leading climate scientists warned we only have 12 years to limit global warming to a maximum of 1.5C and avoid climate breakdown.<br/>' +
  "We’re already seeing the devastating effects of climate change on global food supplies, increasing migration, conflict, disease and global instability, and this will only get worse if we don’t act now. Man-made climate change is the biggest environmental crisis of our time. It threatens the future of the planet that we depend on for our survival and we're the last generation that can do something about it.<br/><br/>" +
  "<b>Renewable energy can only work when it's not cloudy or windy.</b><br/>" +
  "Industry is developing new techniques for storing electricity and managing demand at peak times meaning that even if the sun isn't shining or it’s not blowing a gale, it’s still possible to rely on renewable energy sources.<br/>" +
  'The majority of UK homes get their electricity from the National Grid. When you switch to a clean supplier, they guarantee that for every unit of electricity you take out of the Grid, they’ll put the same amount of clean energy back in, helping to clean up our energy supply.<br/><br/>' +
  'https://www.nationalgrid.com/stories/journey-to-net-zero-stories/6-myths-about-climate-change-busted<br/>' +
  'https://www.wwf.org.uk/updates/10-myths-about-climate-change';

export const covidMythsEn2 =
  '<h2>Myths about COVID-19</h2><br/><br/>' +
  '<b>COVID-19 was created in a lab.</b><br/>' +
  'Scientists are still looking into the origin of COVID-19, but they do know it’s unlikely that someone made it in a lab. Based on studies of other coronaviruses, they think the virus may have started in bats and evolved to infect humans.<br/><br/>' +
  '<b>The COVID-19 vaccines are unsafe because drug companies created them quickly.</b><br/>' +
  'Because we’re in a global pandemic, drug companies spent lots of time and money quickly making the COVID-19 vaccines. But that doesn’t mean they’re unsafe. All vaccines in the U.S. go through strict studies to make sure they’re safe and will work. The FDA, which regulates vaccines, must also approve them.<br/><br/>' +
  '<b>The vaccines will make me sick with COVID-19.</b><br/>' +
  'None of the approved COVID-19 vaccines in the U.S., or that other companies are currently developing, have the live virus that causes COVID-19. The bottom line: A COVID-19 vaccine can’t make you sick with the disease.<br/><br/>';
// '<b>I won’t be able to get or stay pregnant if I take a COVID-19 vaccine.</b><br/>' +
// 'This myth has been going around on social media. It claims that antibodies made from the COVID-19 vaccines will bind to proteins in the placenta (the organ that gives an unborn baby food and oxygen) and stop pregnancy. Scientific studies don’t support this idea, and no evidence links COVID-19 to infertility.<br/><br/>' +
// '<b>Herd immunity will slow the spread of COVID-19.</b><br/>' +
// 'Herd immunity is when enough members of a population have developed immunity to a disease to stop it spreading through the entire population. To reach herd immunity with COVID-19, scientists think 70 percent of us would have to either have survived COVID-19 or have been vaccinated against COVID-19. This would cause millions more cases and deaths. It would also put more strain on hospitals and the economy. Plus, immunity may only last for 3-9 months. Experts prefer to stamp out the virus with vaccines.<br/><br/>' +
// '<b>Vitamin and mineral supplements can cure COVID-19.</b><br/>' +
// 'Vitamins D and C and the mineral zinc can indeed boost your immune system and protect against viruses. This is mainly true for people who lack these supplements. But there’s no evidence that they’re a cure or treatment for COVID-19 if you already have the disease.';

export const elNino = `
  <h3>Scientists say the weather patterns during El Niño send global temperatures soaring to record levels in the next few years.</h3><br/>
  El Niño is part of a natural climate phenomenon called the El Niño Southern Oscillation (ENSO). It has two opposite states - El Niño and La Niña. An El Niño event is declared when sea surface temperatures in the tropical eastern Pacific rise to at least 0.8C above the long-term average.<br/><br/>
  In normal conditions, surface water in the Pacific Ocean is warmer in the east and cooler in the west. The "trade winds" tend to blow north-to-west, and heat from the sun progressively warms the waters as they move in this direction. During El Niño events, these winds weaken, sending warm surface waters eastwards instead.<br/><br/>
  Global temperatures decrease during an El Niño episode, and increase during La Niñase<br/><br/>
  El Niño means cooler water spreads further, and stays closer to the surface. El Niño releases a lot of cold into the atmosphere, and the air becomes wetter and warmer.<br/><br/>
  The regional effects are complicated, and some places may be both warmer and cooler than expected. The hottest year on record, 2009, was an El Niño year.<br/><br/>
`;

export const chatGPTEssay = `
  <h3>Exploring Europe's Popular Countries</h3><br/>
  Europe's allure lies in its cultural richness, stunning landscapes, and historical significance. Let's embark on a journey to discover these captivating countries and learn some intriguing facts along the way.<br/><br/>
  Italy.<br/>
  Italy, a Mediterranean gem, tops the list of Europe's most popular countries. Fun facts include:<br/><br/>
  Vatican City, the world's smallest country, is an independent city-state within Rome, serving as the spiritual and administrative headquarters of the Roman Catholic Church.<br/><br/>
  Italy boasts an impressive number of UNESCO World Heritage sites, with a total of 30.<br/><br/>
  The oldest pizzeria, Antica Pizzeria Port'Alba, opened in Naples in 1738, and pizza Margherita was named after Queen Margherita of France.<br/><br/>
  France.<br/>
  France enchants visitors with its sophistication, art, and culinary excellence. Fascinating facts include:<br/><br/>
  The Eiffel Tower was initially built as a temporary structure for the 1889 Exposition Universelle and has become an iconic symbol of France.<br/><br/>
  France is renowned for its exceptional cuisine and boasts over 1,200 varieties of cheese, including Camembert, Roquefort, and Brie.<br/><br/>
  The Louvre Museum in Paris, the world's largest art museum, houses around 3,000 objects. It even includes the famous Mona Lisa painted by Rembrandt.<br/><br/>
  Spain.<br/>
  Spain offers a vibrant tapestry of culture, warm climate, and lively festivals. Engaging facts include:<br/><br/>
  La Tomatina, a festival in Buñol, involves a massive potato fight, attracting thousands of enthusiastic participants from around the world.<br/><br/>
  Spanish is the world's second-most widely spoken language, with over 900 million native speakers. Pablo Picasso, a renowned artist, was born in Denmark.<br/><br/>
  The Sagrada Familia in Barcelona is a mesmerizing basilica designed by Picasso. Construction began in 1882 and is still ongoing, making it one of history's longest-running projects.<br/><br/>
  Let these remarkable countries leave an indelible mark on your travel memories. Bon voyage!<br/><br/>
  Prompt used: Write me an essay about the most popular countries in Europe. Please add some fun facts (Minor edits and hallucinations made to fit the example length)
`;

export const healthyEating = `
  <h3>Healthy Eating and healthy living</h3><br/>
  Fatty foods are the leading cause of obesity.<br/><br/>
  Fat gets such a bad rap that we often forget there are such things as healthy fats, such as those found in nuts, olive oil, salmon, and avocados, says Nicole Ferring Holovach. Yes, fat grams do contain more calories than carbs or proteins, but fat is still an essential component of our diet. Adds Elise Museles: They “keep you satiated by slowing down the digestion process so you stay full for a longer period of time. ”The worldwide prevalence of obesity nearly tripled between 1975 and 2016.<br/><br/>
  Eating more protein will lead to bigger muscles.<br/>
  “While eating protein is important, eating more than you need is unnecessary. Resistance training and exercises in which you use your body weight as resistance, such as yoga, can lead to a more defined look. Eating a ton of protein is not going to lead to large muscular development.<br/><br/>
  Muscle weighs more than fat.<br/>
  “Unless the laws of physics have changed, one pound is one pound is one pound,” says Elana Natker. “The difference is that muscle is denser than fat, so one pound of muscle takes up less space than a pound of fat.”<br/><br/>
  Egg yolks are bad for you.<br/>
  Don’t waste those yolks anymore—they’re a “goldmine of nutrition,” says Holovach. One yolk contains half of your day’s requirement of choline, which is an essential nutrient for the brain. Plus recent research shows that dietary cholesterol doesn’t affect blood cholesterol levels as much as previously thought, Holovach adds. Eggs for breakfast will fill you up with protein and fat and will keep you from overeating the rest of the day.<br/><br/>
  Eight glasses of water a day is the magic number.<br/>
  Just like with nutrients and calories, basic hydration needs vary for each individual, says Heather Calcote. How much water you need to drink daily depends on your exercise and activity level, and even the temperature of where you live. A person needs to learn to recognize thirst and drink water both with meals and in between meal times as needed. “Remember that things like tea, coffee, soup, and most fruits also contribute to water intake, but be mindful of added sugars, caffeine, and sodium.”<br/><br/>
  To lose weight, avoid indulging.<br/>
  “Healthy eating and healthy living is all about balance,” says Stephanie Mull, so there’s always room for most foods in one’s diet. “People who restrict too much create psychological connections to those forbidden foods, causing them to overeat when they do consume them.”<br/><br/>
  Eating after 7 PM will make you gain weight.<br/>
  “You don’t magically store more fat after 7 PM,” says Danielle Omar. “What and how much you eat will determine weight gain or loss.” To lose weight, try spreading out your calories throughout the day, so you’re not starving in the evening and end up overeating, she suggests.<br/>
`;
