import { Link, Stack } from '@mui/material';
import Logo from 'assets/img/Logo.png';

const Header = () => {
  return (
    <Stack>
      <Stack textAlign="center">
        <Link
          href="https://factiverse.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Logo} alt="Factiverse Logo" height={'70'} />
        </Link>
      </Stack>
    </Stack>
  );
};

export default Header;
