import { Badge, IconButton, Stack } from '@mui/material';
import { useMemo } from 'react';
import Bing from 'assets/img/filter/bing.png';
import FactiSearch from 'assets/img/filter/factisearch.png';
import Google from 'assets/img/filter/google.png';
import SemanticScholar from 'assets/img/filter/semanticScholar.png';
import Wikipedia from 'assets/img/filter/wikipedia.svg';
import You from 'assets/img/filter/you.png';
import { useAppSelector } from 'core/store/hooks';
import { YELLOW } from 'utils/theme';

const dataSources = [
  { value: 'Google', logo: Google, width: '70px' },
  { value: 'Bing Search', logo: Bing, width: '70px' },
  { value: 'Wikipedia', logo: Wikipedia, width: '70px' },
  {
    value: 'Semantic Scholar',
    logo: SemanticScholar,
    logoWidth: '130px',
  },
  {
    value: 'ElasticSearch',
    logo: FactiSearch,
    width: '80px',
  },
  {
    value: 'You.com',
    logo: You,
    width: '70px',
  },
];

const DataSources = ({
  selectedDatabases,
  onChangeDatabase,
}: {
  selectedDatabases: string[];
  onChangeDatabase: (value) => void;
}) => {
  const claims = useAppSelector((state) => state.claims.claims);

  const claimsSearchEngines = useMemo(
    () =>
      claims.flatMap((claim) =>
        claim.evidence?.flatMap((evidence) => evidence.searchEngine)
      ),
    [claims]
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      py={1}
      flexWrap="wrap"
      useFlexGap
    >
      {dataSources.map((source, key) => (
        <Badge
          badgeContent={
            claimsSearchEngines.filter((engine) => engine === source.value)
              .length
          }
          key={key}
          sx={{
            '& .MuiBadge-badge': {
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid',
              top: 5,
              right: 5,
            },
          }}
        >
          <IconButton
            size="small"
            sx={{
              padding: '8px',
              width: 'fit-content',
              borderRadius: '20px',
              border: '1.5px solid rgba(248, 196, 8, 0.3)',
              cursor: 'pointer',
              background: selectedDatabases.includes(source.value)
                ? 'rgba(248, 196, 8, 0.2)'
                : 'auto',
              '&:hover': {
                border: `1.5px solid ${YELLOW}`,
              },
            }}
            onClick={() =>
              onChangeDatabase(
                selectedDatabases.includes(source.value)
                  ? selectedDatabases.filter((value) => source.value !== value)
                  : [...selectedDatabases, source.value]
              )
            }
          >
            <img
              src={source.logo}
              alt={source.value}
              width={source.width}
              height="25px"
            />
          </IconButton>
        </Badge>
      ))}
    </Stack>
  );
};

export default DataSources;
