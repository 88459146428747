import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { YELLOW } from 'utils/theme';

export const LoadingPage = () => {
  return (
    <Grid container justifyContent="center" py={4} pl={4} spacing={2}>
      <Box pt={'25vh'}>
        <CircularProgress size="100px" sx={{ color: YELLOW }} />
      </Box>
    </Grid>
  );
};

export default LoadingPage;
