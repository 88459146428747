import React from 'react';
import TutorialCard from './TutorialCard';
import { StepType } from '@reactour/tour';

// ReacTour https://www.npmjs.com/package/@reactour/tour?activeTab=readme
export const tutorial: StepType[] = [
  {
    selector: '#content',
    position: 'bottom',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Welcome to Factiverse AI Editor!',
            subtitle: 'The credible online text editor.',
          }}
        />
      );
    },
  },
  {
    selector: '#analyze',
    position: 'center',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Fact-check a text',
            subtitle: 'Click "Analyze" to find claims and relevant sources.',
          }}
        />
      );
    },
  },
  {
    selector: '#claims',
    position: 'bottom',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Explore the results',
            subtitle:
              'Get a list of all claims in the text and sources which support or dispute it.',
          }}
        />
      );
    },
  },
  {
    selector: '#filter',
    position: 'bottom',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Filter and sort the results',
            subtitle: 'Select the sources you are interested in.',
          }}
        />
      );
    },
  },
  {
    selector: '#editor',
    position: 'bottom',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Add your own text',
            subtitle:
              'Clear the default text and write or paste your own text.',
          }}
        />
      );
    },
  },
  {
    selector: '#editor',
    position: 'center',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Manually analyze a claim',
            subtitle:
              'If a claim was not detected automatically you can select it and click "Analyze"',
          }}
        />
      );
    },
  },
  {
    selector: '#content',
    position: 'bottom',
    content: () => {
      return (
        <TutorialCard
          page={{
            title: 'Development in progress',
            subtitle:
              'Factiverse AI Editor is still in development, please bear with us if any issues occur.',
          }}
        />
      );
    },
  },
];
