import CloseIcon from '@mui/icons-material/Close';
import DiamondIcon from '@mui/icons-material/Diamond';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  setClaimResults,
  setIsNoDataFound,
  setLinearProgressValue,
  setSearchValue,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { getErrorText, isTooLongText } from 'pages/PlaygroundV2Page/helpers';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import { useDispatch } from 'react-redux';
import { BORDER_COLOR, BRIGHT_YELLOW, GREY, RED } from 'utils/theme';

export const SearchFieldBody = () => {
  const dispatch = useDispatch();
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const checkClaim = useCheckClaim();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    dispatch(setSearchValue(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!isTooLongText(searchValue) && searchValue.length > 0) {
        checkClaim(searchValue);
      }
    }
  };

  return (
    <TextField
      id="outlined-basic"
      placeholder="Type or paste your text here"
      variant="outlined"
      sx={{
        background: 'white',
        '& fieldset': { border: 'none' },
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
      }}
      value={searchValue}
      multiline
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchValue.length > 0 && (
              <IconButton
                onClick={() => {
                  dispatch(setSearchValue(''));
                  dispatch(setClaimResults({} as Claim));
                  dispatch(setLinearProgressValue(0));
                  dispatch(setIsNoDataFound(false));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
            {searchValue.length > 0 && (
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                disabled={isTooLongText(searchValue)}
                onClick={() => checkClaim(searchValue)}
              >
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SearchFieldFooter = () => {
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        background: 'white',
        borderBottomRightRadius: claimLoading ? '0px' : '12px',
        borderBottomLeftRadius: claimLoading ? '0px' : '12px',
        borderTop: `1px solid ${BORDER_COLOR}`,
        paddingY: '12px',
        paddingX: '30px',
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ color: isTooLongText(searchValue) ? RED : GREY, pr: 1 }}
      >
        {getErrorText(searchValue) ?? 'Free version 30 words'}
      </Typography>
      <IconButton
        sx={{
          background: '#feedb4',
          border: `1px solid ${BRIGHT_YELLOW}`,
          width: '1.5rem',
          height: '1.5rem',
        }}
      >
        <DiamondIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Stack>
  );
};
