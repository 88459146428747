import React, { useState } from 'react';
import ThumbsUpDown from 'components/ThumbsUpDown';
import { IconButton, Paper, Stack, Tooltip } from '@mui/material';
import Popup from 'reactjs-popup';
import FeedbackIcon from '@mui/icons-material/Feedback';

/*
 * A feedback element with a short description and thumbs up/down.
 * Lets the user give feedback on the source support.
 */
interface FeedbackSourceProps {
  claim: Claim;
  source: Source;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
}

const FeedbackSource: React.FC<FeedbackSourceProps> = ({
  claim,
  source,
  onFeedbackSourceSupport,
  onFeedbackSourceRelevance,
}) => {
  const [thumbsUp, setThumbsUp] = useState<Array<boolean | undefined>>([
    undefined,
    undefined,
  ]);

  const onChangeRelevance = (newThumbsUp: boolean) => {
    onFeedbackSourceRelevance(claim, source, newThumbsUp);
    setThumbsUp(
      thumbsUp.map((item, index) => {
        if (index == 0) {
          return newThumbsUp;
        } else {
          return item;
        }
      })
    );
  };
  const onChangeSupport = (newThumbsUp: boolean) => {
    onFeedbackSourceSupport(claim, source, newThumbsUp);
    setThumbsUp(
      thumbsUp.map((item, index) => {
        if (index == 1) {
          return newThumbsUp;
        } else {
          return item;
        }
      })
    );
  };

  let supportString = 'Do you agree that this source is ';
  if (source.softmaxScore[1] <= 0.4) {
    supportString += 'disputing the claim?';
  } else if (source.softmaxScore[1] >= 0.6) {
    supportString += 'supporting the claim?';
  } else {
    supportString += 'mixed?';
  }

  return (
    <Popup
      trigger={
        <Tooltip title={'Provide feedback'} arrow>
          <IconButton size={'small'}>
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
      }
      position={'bottom right'}
    >
      <Paper elevation={4}>
        <Stack p={2} spacing={2} alignItems="center">
          <ThumbsUpDown
            thumbsUp={thumbsUp[0]}
            labelBefore={
              'Do you agree that this source is relevant to the claim?'
            }
            onChangeThumbsUpDown={onChangeRelevance}
          />
          <ThumbsUpDown
            thumbsUp={thumbsUp[1]}
            labelBefore={supportString}
            onChangeThumbsUpDown={onChangeSupport}
          />
        </Stack>
      </Paper>
    </Popup>
  );
};

export default FeedbackSource;
