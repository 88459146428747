import { useAuth0 } from '@auth0/auth0-react';
import useSettings from 'hooks/useSettings';
import LoadingPage from 'pages/LoadingPage';
import React, { ReactNode, useEffect, useState } from 'react';
import { getUserRequest, postUserRequest } from './API/user';

interface SettingsWrapperProps {
  children: ReactNode;
}

const SettingsWrapper: React.FC<SettingsWrapperProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth0();
  const { handleSettingsChange } = useSettings();

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoading(false);
      return;
    }

    setTimeout(
      () =>
        getUserRequest()
          .then((response) => {
            handleSettingsChange(
              response.data.allow_tracking,
              response.data.seen_tutorial,
              response.data.onboarding_questions
            );
          })
          .catch((error) => {
            if (error.response?.status == '404') {
              // user doesn't exist yet, set values to default
              postUserRequest(false, false, []);
            }
          })
          .finally(() => {
            setIsLoading(false);
          }),
      100
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) return <LoadingPage />;
  return <>{children}</>;
};

export default SettingsWrapper;
