import { useAuth0 } from '@auth0/auth0-react';
import { MenuOutlined } from '@mui/icons-material';
import {
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Logo from 'assets/img/Logo.png';
import Profile from 'components/Profile';
import User from 'components/User';
import LoginButton from 'components/buttons/LoginButton';
import SignupButton from 'components/buttons/SignupButton';
import React, { useCallback, useMemo, useState } from 'react';
import { BORDER_COLOR, BORDER_RADIUS, ISMOBILE_BREAKPOINT } from 'utils/theme';

const ReusableLink = ({ link, title }: { link: string; title: string }) => {
  return (
    <Typography>
      <Link sx={{ textDecoration: 'none', color: 'black' }} href={`${link}`}>
        {title}
      </Link>
    </Typography>
  );
};

const links = [
  { link: '/', title: 'Home' },
  { link: '/faq', title: 'FAQ' },
  { link: '/pricing', title: 'Pricing' },
];

const Header = () => {
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const [userProfileAnchorEl, setUserProfileAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleClickUserProfile = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setUserProfileAnchorEl(userProfileAnchorEl ? null : event.currentTarget);
    },
    [userProfileAnchorEl]
  );

  const handleCloseUserProfile = () => {
    setUserProfileAnchorEl(null);
  };

  const openUserProfile = useMemo(
    () => Boolean(userProfileAnchorEl),
    [userProfileAnchorEl]
  );
  const idUserProfile = useMemo(
    () => (openUserProfile ? 'simple-popper' : undefined),
    [openUserProfile]
  );

  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <Grid
      item
      container
      height="72px"
      alignItems="center"
      sx={{
        background: 'white',
        borderRadius: isMobile ? 0 : BORDER_RADIUS,
        boxShadow: '-4px 5px 28px -3px rgba(232, 234, 235,0.75)',
        border: `1px solid ${BORDER_COLOR}`,
        zIndex: 10,
        position: 'relative',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        justifyItems={isMobile ? 'center' : 'center'}
        item
        xs={isMobile ? 9 : 10}
        wrap={isMobile ? 'nowrap' : 'wrap'}
      >
        <Grid item ml={isMobile ? 2 : 6}>
          <Tooltip title="Visit factiverse.no">
            <Link
              href="https://factiverse.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Logo}
                alt="Factiverse Logo"
                width={'210'}
                height={'65'}
              />
            </Link>
          </Tooltip>
        </Grid>
        <Grid item mr={isMobile ? 2 : 6}>
          {isMobile ? (
            <>
              <Button
                id="basic-button"
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: 'black', pr: 3 }}
              >
                <MenuOutlined />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {links.map((link, key) => (
                  <MenuItem key={key} onClick={handleCloseMenu}>
                    <ReusableLink link={link.link} title={link.title} />
                  </MenuItem>
                ))}
                <MenuItem onClick={handleCloseMenu}>
                  <Typography>
                    <Link
                      href="https://factiverse.ai/api-offerings"
                      target="blank"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      API
                    </Link>
                  </Typography>
                </MenuItem>

                {!isAuthenticated && (
                  <MenuItem onClick={handleCloseMenu}>
                    <LoginButton />
                  </MenuItem>
                )}
              </Menu>
            </>
          ) : (
            <Stack spacing={4} direction="row">
              {links.map((link, key) => (
                <ReusableLink key={key} link={link.link} title={link.title} />
              ))}
              <Typography>
                <Link
                  href="https://factiverse.ai/api-offerings"
                  target="blank"
                  sx={{
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  API
                </Link>
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        justifyContent="center"
        sx={{ borderLeft: `1px solid ${BORDER_COLOR}` }}
      >
        {isAuthenticated ? (
          <User
            handleClickUserProfile={handleClickUserProfile}
            openUserProfile={openUserProfile}
          />
        ) : (
          <Grid container justifyContent="space-evenly">
            <SignupButton />
            {!isMobile && <LoginButton />}
          </Grid>
        )}
      </Grid>
      <Profile
        open={openUserProfile}
        anchorEl={userProfileAnchorEl}
        handleClose={handleCloseUserProfile}
        id={idUserProfile as string}
      />
    </Grid>
  );
};

export default Header;
