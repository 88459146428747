import { Box, Grid, Typography } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import { useNavigate } from 'react-router-dom';

const TrialInfoMessage = () => {
  const navigate = useNavigate();
  return (
    <Box
      p={2}
      mt={2}
      sx={{
        backgroundColor: '#fff7d9',
        borderRadius: '3px',
      }}
    >
      <Grid container justifyContent="center" alignItems="baseline">
        <Typography align="center" pb={1} pr={2}>
          Your free trial has run out. Please subscribe to continue checking
          claims
        </Typography>
        <YellowBorderButton
          onClick={() => {
            navigate('/pricing');
          }}
        >
          Upgrade Subscription
        </YellowBorderButton>
      </Grid>
    </Box>
  );
};

export default TrialInfoMessage;
