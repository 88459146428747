import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import celebratingWoman from 'assets/img/celebratingWoman.png';
import manOnLadder from 'assets/img/manOnLadder.png';
import manWithLaptop from 'assets/img/manWithLaptop.png';
import AllowTracking from 'components/AllowTracking/AllowTracking';
import YellowButton from 'components/buttons/YellowButton';
import useSettings from 'hooks/useSettings';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';
import OnboardingItem from './OnboardingItem';
import Stepper from './Stepper';

const OnboardingQuestions = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWritingMatter, setSelectedWritingMatter] = useState<string[]>(
    []
  );
  const [selectedTypeOfWriting, setSelectedTypeOfWriting] = useState<string[]>(
    []
  );

  const [selectedPlace, setSelectedPlace] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<string[]>([]);
  const [selectedAge, setSelectedAge] = useState<string[]>([]);
  const [allowTrackingRequired, setAllowTrackingRequired] =
    useState<boolean>(false);

  const {
    allowTracking,
    onboardingQuestions,
    handleAllowTrackingChange,
    handleOnboardingQuestionsChange,
  } = useSettings();

  const handleNext = () => {
    if (!allowTracking) {
      setAllowTrackingRequired(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const questions = [
    {
      id: 0,
      question: 'What is your age?',
      answers: [
        'Under 18',
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55-64',
        '65-74',
        'over 75',
      ],
      selected: selectedAge,
      setSelected: setSelectedAge,
    },
    {
      id: 1,
      question: 'What matters most to you?',
      answers: [
        'Improving content quality',
        'Avoiding any factual errors',
        'Finding references to my text',
        'Other',
      ],
      selected: selectedWritingMatter,
      setSelected: setSelectedWritingMatter,
    },
    {
      id: 2,
      question: 'Which type of activity do you plan to use Factiverse for?',
      answers: [
        'Editorial media',
        'Blogs and freelance writing',
        'Fact-checking',
        'Marketing',
        'Research and studies',
        'Other',
      ],
      selected: selectedTypeOfWriting,
      setSelected: setSelectedTypeOfWriting,
    },
    {
      id: 3,
      question: 'How did you hear about Factiverse?',
      answers: [
        'News article',
        'Social media post',
        'Friend/Colleague',
        'Other',
      ],
      selected: selectedPlace,
      setSelected: setSelectedPlace,
    },
    {
      id: 4,
      question: 'Which industry do you work in?',
      answers: [
        'Accounting & Administration',
        'Banking & Financial Services',
        'Business Consulting',
        'Compliance Consulting',
        'Education',
        'Life Sciences & Research',
        'Engineering',
        'Public Services',
        'Healthcare',
        'Insurance',
        'Legal',
        'Marketing & Communications',
        'Editorial Media',
        'Sustainability',
        'Resources & Energy',
        'Other',
      ],
      selected: selectedIndustry,
      setSelected: setSelectedIndustry,
    },
  ];

  const images = [
    { image: celebratingWoman, alt: 'celebratingWoman' },
    { image: manOnLadder, alt: 'manOnLadder' },
    { image: manWithLaptop, alt: 'manWithLaptop' },
  ];

  useEffect(() => {
    if (onboardingQuestions.length != 0) {
      navigate('/home');
    }
  }, [navigate, onboardingQuestions]);

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ background: 'white', overflow: 'auto' }}>
        <Grid container height="100vh">
          {!isMobile && (
            <Grid item xs={5} textAlign="center" height="100%">
              {images.map((image, key) => (
                <img
                  key={key}
                  src={image.image}
                  alt={image.image}
                  height="33%"
                />
              ))}
            </Grid>
          )}
          <Grid
            item
            xs={isMobile ? 12 : 7}
            width={isMobile ? '100%' : '70vw'}
            sx={{ background: 'rgba(248, 196, 6, 0.3)' }}
            border="1px solid grey"
          >
            <Box
              top="50%"
              left={isMobile ? '50%' : '40%'}
              sx={{ transform: 'translate(-50%, -50%)' }}
              position="relative"
              width={isMobile ? '80vw' : '40vw'}
            >
              {activeStep === 0 && (
                <Box>
                  <Typography
                    position="absolute"
                    id="modal-modal-title"
                    variant={isMobile ? 'h5' : 'h4'}
                    fontWeight={600}
                    component="h2"
                    top={isMobile ? '-50px' : '-80px'}
                  >
                    Welcome, {user?.given_name ?? ''}!
                  </Typography>
                </Box>
              )}

              <OnboardingItem
                onboardingQuestion={questions[activeStep].question}
                questionValues={questions[activeStep].answers}
                onClick={(v) =>
                  questions[activeStep].setSelected(
                    questions[activeStep].selected?.includes(v)
                      ? questions[activeStep].selected?.filter((m) => m !== v)
                      : [...questions[activeStep].selected, v]
                  )
                }
                selectedValues={questions[activeStep].selected}
                onChangeOtherValue={(event: ChangeEvent<HTMLInputElement>) => {
                  questions[activeStep].setSelected([event.target.value]);
                }}
                otherValue={questions[activeStep].selected}
              />
              {activeStep === 0 && (
                <Box py={3}>
                  <AllowTracking
                    allowTracking={allowTracking}
                    setAllowTracking={handleAllowTrackingChange}
                  />
                  {!allowTracking && allowTrackingRequired && (
                    <Typography color="error" variant="subtitle2">
                      Please agree with the Terms and Conditions
                    </Typography>
                  )}
                </Box>
              )}

              <Stepper
                steps={questions.length}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                disabledSkip={!allowTracking}
                submitButton={
                  <YellowButton
                    disabled={!allowTracking}
                    onClick={() => {
                      const onboardingItems = questions.map((item) => {
                        return {
                          question: item.question,
                          answer: item.selected.join(','),
                        };
                      });
                      handleOnboardingQuestionsChange(onboardingItems);
                      navigate('/home');
                    }}
                  >
                    Continue
                  </YellowButton>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default OnboardingQuestions;
