import { Typography } from '@mui/material';
import { LIGHT_YELLOW } from 'utils/theme';

const NoDataFoundText = () => {
  return (
    <Typography p={2} bgcolor={LIGHT_YELLOW} mt={2} borderRadius="12px">
      Thank you for your request! Unfortunately, we could not find any data
      matching your search criteria. Please try refining your query.
    </Typography>
  );
};

export default NoDataFoundText;
