import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import claimsSlice from './claimsSlice';
import claimsV2Slice from './claimsV2Slice';
import loginSlice from './loginSlice';
import sourceFilterSlice from './sourceFilterSlice';
import userSlice from './userSlice';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  claims: claimsSlice,
  sourceFilter: sourceFilterSlice,
  login: loginSlice,
  user: userSlice,
  claimsV2: claimsV2Slice,
});

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

// Create the persistor
export const persistor = persistStore(store);

// Export types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
