import { TourProvider } from '@reactour/tour';
import NoBorderButton from 'components/buttons/NoBorderButton';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import useSettings from 'hooks/useSettings';
import React, { PropsWithChildren } from 'react';
import { tutorial } from './TutorialSteps';

type TourWrapperProps = PropsWithChildren<object>;

const TutorialWrapper: React.FC<TourWrapperProps> = ({ children }) => {
  const { handleSeenTutorialChange } = useSettings();
  const nextButton = (next) => {
    return (
      <YellowBorderButton
        onClick={() => {
          const lastStep = next.currentStep === next.stepsLength - 1;
          if (lastStep) {
            next.setIsOpen(false);
            handleSeenTutorialChange(true);
          } else {
            next.setCurrentStep((s) =>
              s === next.steps?.length - 1 ? 0 : s + 1
            );
          }
        }}
      >
        {next.currentStep === next.stepsLength - 1 ? "I'm ready" : 'Next'}
      </YellowBorderButton>
    );
  };
  const prevButton = (prev) => {
    if (prev.currentStep == 0) {
      return (
        <NoBorderButton
          onClick={() => {
            prev.setIsOpen(false);
            handleSeenTutorialChange(true);
          }}
        >
          Skip
        </NoBorderButton>
      );
    }
    return (
      <NoBorderButton
        onClick={() =>
          prev.currentStep > 0 && prev.setCurrentStep(prev.currentStep - 1)
        }
      >
        Previous
      </NoBorderButton>
    );
  };
  return (
    <TourProvider
      steps={tutorial}
      showBadge={false}
      onClickClose={({ setIsOpen }) => {
        handleSeenTutorialChange(true);
        setIsOpen(false);
      }}
      showDots={false}
      nextButton={nextButton}
      prevButton={prevButton}
      position={'center'}
      onClickMask={() => null}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: 8,
          maxWidth: 'auto',
        }),
        close: (base) => ({
          ...base,
          top: 8,
          right: 10,
          width: 15,
          height: 15,
        }),
      }}
    >
      {children}
    </TourProvider>
  );
};

export default TutorialWrapper;
