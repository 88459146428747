import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

const SignupPage = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: '/postsignup',
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }, [loginWithRedirect]);

  return <Typography></Typography>;
};

export default SignupPage;
