import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import Sum from 'components/Sum';
import { useAppSelector } from 'core/store/hooks';
import useScoreCount from 'pages/PlaygroundV2Page/hooks/useScoreCount';
import { LIGHT_GREEN, LIGHT_PINK, LIGHT_YELLOW } from 'utils/theme';

const palette = [LIGHT_GREEN, LIGHT_YELLOW, LIGHT_PINK];

const pieParams = { height: 200, margin: { right: 5 } };

const Donut = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  const { supportingSources, mixedSources, disputingSources } = useScoreCount();

  const donutData = [
    { id: 1, value: supportingSources?.length ?? 0, title: 'Supporting' },
    { id: 2, value: mixedSources?.length ?? 0, title: 'Mixed' },
    { id: 3, value: disputingSources?.length ?? 0, title: 'Disputing' },
  ];

  const series = [{ data: donutData, innerRadius: 50, outerRadius: 70 }];

  const CustomLegend = ({ data, palette }) => (
    <Stack gap={1}>
      {data.map((entry, index) => (
        <Stack direction="row" gap={1} alignItems="center" key={entry.id}>
          {claimLoading ? (
            <Skeleton width={200} height={30} />
          ) : (
            <Stack direction="row" gap={2}>
              <Sum
                color={palette[index % palette.length]}
                count={entry.value}
                isSum={true}
              />
              <Typography variant="subtitle1">{entry.title}</Typography>
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Grid container>
      <Grid container item xs={6} justifyContent="center" width="100%">
        {claimLoading ? (
          <Skeleton variant="circular" width={150} height={150} />
        ) : (
          <PieChart colors={palette} series={series} {...pieParams} />
        )}
      </Grid>
      <Grid container item xs={6} justifyContent="center" alignItems="center">
        <CustomLegend data={donutData} palette={palette} />
      </Grid>
    </Grid>
  );
};

export default Donut;
