export const range = (from, to, step) =>
  [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);

function stripHtmlTags(html: string): string {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
}

export const calculateChecksum = (input1: string, input2: string) => {
  const normalizedInput1 = stripHtmlTags(input1).replace(/\s/g, '');
  const normalizedInput2 = input2.replace(/\s/g, '');

  let checksum1 = 0;
  for (let i = 0; i < normalizedInput1.length; i++) {
    checksum1 += normalizedInput1.charCodeAt(i);
  }

  let checksum2 = 0;
  for (let i = 0; i < normalizedInput2.length; i++) {
    checksum2 += normalizedInput2.charCodeAt(i);
  }

  if (checksum1 === checksum2) {
    return true;
  } else {
    return false;
  }
};
